<template>
  <div class="card">
    <div class="image">
      <v-img v-if="hasImage" :src="user.image" />
      <div class="initials" v-if="!hasImage">{{ initials }}</div>
    </div>
    <div class="name-card">
      <div class="name">{{ user.name }} {{ user.surname }}</div>
      <div class="email">{{ user.email }}</div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    user: {
      default: {},
    },
  },
  computed: {
    initials() {
      if (this.user) {
        return this.user.name.substr(0, 1) + this.user.surname.substr(0, 1);
      }
      return ":)";
    },
    hasImage() {
      return (
        this.user && this.user.image && !this.user.image.match(/sd_avatar_blue/)
      );
    },
  },
};
</script>
<style scoped>
.card {
  border: var(--outline-dark-border);
  border-radius: 4px;
  padding: 16px 16px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.card > .image > * {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.card > .image > .initials {
  background-color: var(--optix-theme-accentColor);
}
.name-card {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  padding-left: 16px;
  justify-content: center;
  line-height: 20px;
}
.name-card .email {
  font-size: 14px;
}
.initials {
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  color: rgb(var(--optix-theme-borderRGBColor));
}
</style>
