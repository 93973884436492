<template>
  <div class="card">
    <div class="select clickable" @click="select">
      <o-radio :value="radioValue" ref="rad" :disabled="disabled" />
      <div class="name-card">New credit card</div>
    </div>
    <div class="form" v-if="visibleForm == 'spreedly-v1'">
      <credit-card-spreedly
        ref="spreedlyV1"
        :disabled="disabled"
        @success="successHandler"
        @error="errorHandler"
      />
    </div>
    <div class="form" v-if="visibleForm == 'stripe-v1'">
      <credit-card-stripe-v1
        ref="stripeV1"
        :disabled="disabled"
        @success="successHandler"
        @error="errorHandler"
      />
    </div>
    <div class="form" v-if="visibleForm == 'stripe-v2'">
      <credit-card-stripe-v2
        ref="stripeV2"
        :disabled="disabled"
        @success="successHandler"
        @error="errorHandler"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: {
    paymentMethod: {
      default: () => {
        return {};
      },
    },
    selected: {
      default: false,
    },
    disabled: {
      default: false,
    },
  },
  data() {
    return {
      amount: 0,
    };
  },
  computed: {
    ...mapGetters(["paymentGateway"]),
    radioValue() {
      return this.amount;
    },
    visibleForm() {
      if (!this.selected) {
        return "";
      }
      if (this.paymentGateway.processor == "spreedly") {
        return "spreedly-v1";
      }
      if (this.paymentGateway.processor == "stripe") {
        return "stripe-v" + this.paymentGateway.engine;
      }
      return "";
    },
  },
  methods: {
    select() {
      this.$refs.rad.$refs.input.click();
    },
    successHandler(success) {
      this.$emit("success", success);
    },
    errorHandler(error) {
      this.$emit("error", error);
    },
    processCard() {
      switch (this.visibleForm) {
        case "spreedly-v1":
          return this.$refs.spreedlyV1.processCard();
        case "stripe-v1":
          return this.$refs.stripeV1.processCard();
        case "stripe-v2":
          return this.$refs.stripeV2.processCard();
      }
    },
  },
};
</script>
<style scoped>
.card {
  border: var(--outline-dark-border);
  border-radius: 4px;
  padding: 24px 16px 16px;
  display: flex;
  flex-direction: column;
  color: var(--optix-theme-defaultFontColor);
}
.select {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
}
.card .v-radio {
  padding: 0px 8px;
}
.name-card {
  flex-grow: 1;
}
.form {
  padding: 16px 16px 0px;
}
</style>
