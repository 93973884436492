<template>
  <layout-content>
    <o-layout row>
      <h1>{{ bookATourTitle }}</h1>
      <div>
        <div class="left-side" v-if="sideBySide">
          <div class="loader-month" v-if="loadingMonth">
            <loader />
          </div>
          <o-date-picker
            ref="datePicker"
            class="tour-date-picker"
            v-model="dateSelected"
            :weekday-format="formatWeekday"
            :allowed-dates="allowedDate"
            :min="currentDate"
            :max="maxDate"
            @update:picker-date="onChangePickerDate"
          />
        </div>
        <div class="right-side" :class="{ fullsize: !sideBySide }">
          <h1 key="month-picker" class="month-picker" v-if="!sideBySide">
            <span>{{ month }}</span>
          </h1>
          <scrollable-date-picker
            v-if="!sideBySide"
            v-model="dateSelected"
            :maxLength="pickerMaxLength"
            :min="currentDate"
            :max="maxDate"
            class="day-picker"
          />
          <h2 v-if="locations.length > 1">Which location?</h2>
          <o-select
            v-if="locations.length > 1"
            class="mt-4"
            v-model="locationSelected"
            :items="locations"
          />
          <h2 class="mb-4">What time?</h2>
          <div class="duration-picker">
            <div v-if="loadingTimes">
              <skeleton-loader
                v-for="n in 6"
                :key="'skeleton' + n"
                height="48"
                radius="4"
                class="mb-2"
              />
            </div>
            <div
              key="no-times"
              v-if="timeblocks.length == 0 && !loadingTimes"
              class="no-times"
            >
              <o-icon icon="o-icon-clock" />
              <div>Sorry, no times available. Try changing the day.</div>
            </div>
            <div v-if="durationPicker.showStart" class="fade top"></div>
            <div
              v-if="timeblocks.length > 0 && !loadingTimes"
              class="time-options"
              ref="durationPicker"
              @scroll="handleScroll"
              :style="{ 'max-height': durationPickerMaxHeight }"
            >
              <base-button
                v-for="timeblock in timeblocks"
                :key="timeblock.start_timestamp"
                :outlined="true"
                :monocrome="true"
                class="not-bold"
                @click="selectTime(timeblock)"
                >{{ formatTime(timeblock) }}</base-button
              >
            </div>
            <div v-if="durationPicker.showEnd" class="fade"></div>
          </div>
        </div>
      </div>
    </o-layout>
  </layout-content>
</template>
<script>
import { DateTime } from "luxon";
import { mapState, mapMutations } from "vuex";
import Tour from "../mixins/Tour";
import OptixV2Service from "../services/OptixV2Service";
export default {
  mixins: [Tour],
  data() {
    return {
      dateSelected: null,
      monthSelected: null,
      locationSelected: null,
      showDatePicker: false,
      startDayTimestamp: 0,
      currentDate: DateTime.local().toISO().substr(0, 10),
      durationPicker: {
        showStart: false,
        showEnd: false,
      },
      loadingMonth: true,
      loadingTimes: true,
      monthTimeBlocks: {},
      dayTimeBlocks: [],
      locationTimes: {},
      maxDate: DateTime.local().plus({ year: 1 }).toISO().substr(0, 10),
    };
  },
  mounted() {
    this.dateSelected = this.currentDate;
    this.$nextTick(() => {
      this.locationSelected = this.locations[0].value;
      this.handleScroll();
    });
  },
  watch: {
    timeblocks() {
      setTimeout(() => {
        this.handleScroll();
      }, 10);
    },
    dateSelected() {
      this.recalculateDates();
    },
    locationSelected() {
      this.selectLocation(this.location);
      this.recalculateDates();
    },
  },
  computed: {
    ...mapState(["organization", "mode"]),
    month() {
      let date = DateTime.fromISO(this.dateSelected);
      if (date.year == this.currentDate.replace(/-.*$/, "")) {
        return date.toFormat("MMMM");
      }
      return date.toFormat("MMMM, yyyy");
    },
    sideBySide() {
      return this.$vuetify.breakpoint.width >= 950;
    },
    pickerMaxLength() {
      return this.$vuetify.breakpoint.width >= 360 ? 7 : 5;
    },
    locations() {
      if (!this.organization.locations) {
        return [];
      }
      let locations_param_list = (this.$optix.env.get.locations || "")
        .split(",")
        .filter((num) => num.length > 0);

      return this.organization.locations
        .filter(
          // Remove locations not listed by
          (item) => {
            return (
              locations_param_list.length == 0 ||
              locations_param_list.indexOf(item.location_id) !== -1
            );
          }
        )
        .map((location) => {
          return { text: location.name, value: location.location_id };
        });
    },
    timeblocks() {
      let timeblocks = [];
      if (!this.dayTimeBlocks || this.dayTimeBlocks.length == 0) {
        return [];
      }
      let minimumTime = DateTime.local().toSeconds() + 10 * 60;
      this.dayTimeBlocks.map((block) => {
        let start_time = block.start_timestamp;
        if (start_time > 0) {
          for (
            let i = start_time;
            i + this.bookATourDuration <= block.end_timestamp;
            i += this.bookATourStep
          ) {
            // Skip times before the current time
            if (i < minimumTime) {
              continue;
            }
            timeblocks.push({
              start_timestamp: i,
              end_timestamp: i + this.bookATourDuration,
            });
          }
        }
      });
      return timeblocks;
    },
    location() {
      if (
        this.organization &&
        this.organization.locations &&
        this.locationSelected
      ) {
        return this.organization.locations.filter((location) => {
          return this.locationSelected == location.location_id;
        })[0];
      }
      return {
        timezone: DateTime.local().zone.name,
      };
    },
    durationPickerMaxHeight() {
      if (this.sideBySide) {
        return (this.locations.length == 1 ? 510 : 380) + "px";
      }
      if (this.mode == "embed" || this.mode == "modal") {
        return (
          this.$vuetify.breakpoint.height -
          (this.locations.length == 1 ? 280 : 400) +
          "px"
        );
      }
      return "inherit";
    },
  },
  methods: {
    ...mapMutations(["startTourCreation", "selectLocation"]),
    formatWeekday(e) {
      return DateTime.fromISO(e).toFormat("EEE");
    },
    allowedDate(e) {
      return this.monthTimeBlocks[e] ? true : false;
    },
    selectTime(time) {
      this.startTourCreation({
        location: this.location,
        start_timestamp: time.start_timestamp,
        end_timestamp: time.end_timestamp,
      });
      this.$router.push({ name: "tour-request" });
    },
    formatTime(timeblock) {
      if (timeblock.start_timestamp) {
        return DateTime.fromSeconds(timeblock.start_timestamp, {
          zone: this.location.timezone,
        }).toLocaleString(DateTime.TIME_SIMPLE);
      }
      return "";
    },
    handleScroll() {
      if (this.$refs.durationPicker) {
        this.durationPicker.showStart = this.$refs.durationPicker.scrollTop > 0;
        this.durationPicker.showEnd =
          this.$refs.durationPicker.scrollTop <
          this.$refs.durationPicker.scrollHeight -
            this.$refs.durationPicker.clientHeight;
      }
    },
    recalculateDates() {
      if (this.dateSelected && this.location && this.location.location_id) {
        this.loadingTimes = true;
        this.loadMonth(this.location, this.dateSelected.substr(0, 7)).then(
          (month) => {
            if (month[this.dateSelected]) {
              this.dayTimeBlocks = month[this.dateSelected];
            } else {
              this.dayTimeBlocks = [];
            }
            this.loadingTimes = false;
          }
        );
      }
      if (this.monthSelected && this.location && this.location.location_id) {
        this.loadingMonth = true;
        this.loadMonth(this.location, this.monthSelected).then((month) => {
          this.monthTimeBlocks = month;
          this.loadingMonth = false;
        });
      }
    },
    onChangePickerDate(e) {
      this.monthSelected = e;
      this.recalculateDates();
    },
    async loadMonth(location, month) {
      if (!location) {
        return false;
      }
      if (!this.locationTimes[location.location_id]) {
        this.locationTimes[location.location_id] = {};
      }
      // Use the cached one if available
      if (this.locationTimes[location.location_id][month]) {
        return Promise.resolve(this.locationTimes[location.location_id][month]);
      }
      let resolve = OptixV2Service.loadMonth(
        this.organization.subdomain,
        location.location_id,
        location.timezone,
        month
      );
      this.locationTimes[location.location_id][month] = resolve;
      return resolve;
    },
  },
};
</script>
<style scoped>
h1 {
  font-size: 30px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 33px;
  height: 94px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
h2 {
  font-size: 20.2px;
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 23px;
}
.layout {
  display: flex;
  flex-direction: column;
}
.layout > div {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
}
.layout > div > div {
  width: 50%;
}

.month-selector {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 47px;
  font-size: 20.2px;
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 23px;
}
.month-selector .month {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.month-selector .shift .v-icon {
  color: rgba(var(--optix-theme-defaultFontRGBColor), 0.6);
}

.right-side {
  display: flex;
  flex-direction: column;
}

.month-picker {
  font-size: 20px;
  line-height: 20px;
  font-weight: 500;
  text-align: left;
  flex-direction: row;
  display: flex;
  justify-content: flex-start;
  margin: 0px 0px 24px;
  height: 24px;
}

.day-picker {
  margin-bottom: 24px;
}
.duration-picker {
  height: 100%;
}
.time-options .base-button {
  font-size: 16px !important;
  margin-bottom: 8px;
  height: 48px;
}

.time-options::-webkit-scrollbar {
  display: none;
  padding-bottom: 30px;
}
.time-options {
  position: relative;
  overflow-y: auto;
}

.fade {
  position: relative;
  height: 30px;
  background-image: linear-gradient(
    rgba(var(--optix-theme-backgroundRGBColor), 0),
    rgba(var(--optix-theme-backgroundRGBColor), 0.5),
    rgba(var(--optix-theme-backgroundRGBColor), 1)
  );
  width: 100%;
  margin-top: -30px;
}
.fade.top {
  background-image: linear-gradient(
    rgba(var(--optix-theme-backgroundRGBColor), 1),
    rgba(var(--optix-theme-backgroundRGBColor), 0.5),
    rgba(var(--optix-theme-backgroundRGBColor), 0)
  );
  margin-top: 0px;
  margin-bottom: -30px;
  z-index: 1;
}

.no-times {
  width: 100%;
  padding: 50px 0px;
  text-align: center;
}
.no-times .o-icon {
  color: rgba(var(--optix-theme-defaultFontRGBColor), 0.1);
  font-size: 100px;
  margin-bottom: 24px;
}
.left-side {
  position: relative;
}
.loader-month {
  position: absolute;
  z-index: 1;
  top: 38px;
  background-color: rgba(var(--optix-theme-backgroundRGBColor), 0.8);
  height: 360px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 40px;
}
</style>
