/**
 * This service store a set of handy actions interacting with Optix webservices V1
 */
// import _get from "lodash/get";
//import _forEach from "lodash/forEach";
//import _flatMap from "lodash/flatMap";
//import _findIndex from "lodash/findIndex"
// import _find from "lodash/find";
// import { DateTime } from "luxon";

export default {
  ws: null,
  store: null,

  /**
   * Init the library (this should be called at App create method)
   * @param {*} ws
   * @param {*} store
   */
  init(ws, store) {
    this.ws = ws;
    this.store = store;
  },

  /**
   * Lookup a member by an email in a specific organization
   */
  async lookup(payload) {
    return await this.ws.v1Api("POST", "/members/mobile/lookup/", {
      ...payload,
      venue_id: this.store.state.organization.organization_id,
      member_type: "dropin",
    });
  },

  /**
   * Get the default user properties
   */
  async profileFields() {
    return await this.ws.v1Api(
      "GET",
      "/venues/options/profileFields/",
      {},
      {
        params: {
          venue_id: this.store.state.organization.organization_id,
        },
      }
    );
  },

  /**
   * Completes the registration of a pending user, setting email and basic info
   * @param {*} user
   */
  async complete(user) {
    const { name, surname, phone, password, email } = user;
    return await this.ws.v1Api("POST", "/users/registration/complete/", {
      email,
      name,
      surname,
      phone,
      password,
      member_type: "dropin",
    });
  },

  /**
   * Authenticate a user
   * @param {*} user
   */
  async login(user) {
    const { password, email } = user;
    return await this.ws.v1Api("POST", "/auth/login/", {
      venue_id: this.store.state.organization.organization_id,
      password,
      email,
      member_type: "dropin",
    });
  },

  /**
   * Send recover email
   * @param {*} user
   */
  async sendRecoveryEmail(user) {
    const { email } = user;
    return await this.ws.v1Api("POST", "/users/resetPassword/", {
      venue_id: this.store.state.organization.organization_id,
      email,
    });
  },

  /**
   * Send recover email
   * @param {*} user
   */
  async recoverPassword(user) {
    const { email, code, password } = user;
    return await this.ws.v1Api("POST", "/users/resetPassword/", {
      venue_id: this.store.state.organization.organization_id,
      email,
      code,
      password,
    });
  },

  /**
   * Get all payment methods from a member
   * @param {*} member_id
   */
  async getPaymentMethods(member_id) {
    return await this.ws.v1Api(
      "GET",
      "/payments/getPaymentMethods/",
      {},
      {
        params: {
          venue_id: this.store.state.organization.organization_id,
          member_id,
        },
      }
    );
  },

  /**
   * Get all venue information
   */
  async getVenue() {
    return await this.ws.v1Api(
      "GET",
      "/venues/get/" + this.store.state.organization.organization_id,
      {},
      {
        params: {
          scope: "full,membership,payment_gateway",
          portal: 1,
        },
      }
    );
  },

  /**
   * Add a payment method using V1 payment engine
   * @param {*} fields
   */
  async addPaymentMethod(fields) {
    fields.member_type = "dropin";
    return await this.ws.v1Api("POST", "/payments/add/", fields, {});
  },
};
