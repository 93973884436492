<template>
  <layout-content>
    <o-layout row>
      <o-flex class="left-side">
        <user-card :user="user" />
        <h1 class="mt-5">Payment</h1>

        <div class="loader-container" v-if="loading">
          <loader />
        </div>

        <v-radio-group
          v-model="form.payment_method_id"
          :mandatory="false"
          v-if="!loading && !isFreeBooking && requirePaymentMethod"
        >
          <payment-option
            :payment-method="paymentMethod"
            class="mb-3"
            v-for="(paymentMethod, index) in paymentMethods"
            :key="index"
            :disabled="processing"
          />
          <div v-for="(pm, index) in availableRetainableMethods" :key="'new-retainable-'+index">
            <retainable-payment-method
              :organization_id="organization.organization_id"
              :member_account="lookup.member_account"
              :pm="pm"
              :disabled="processing"
              :selected="form.payment_method_id"
              @select="form.payment_method_id = pm.name"
              @added="successHandler"
              v-if="!loading && !processingBookingCreation"
            />
          </div>
          <new-payment-option
            class="mb-3"
            @success="successHandler"
            ref="newPaymentOption"
            :selected="form.payment_method_id == 0"
            @error="errorHandler"
            :disabled="processing"
            v-if="!loading && !processingBookingCreation"
          />
        </v-radio-group>

        <div
          class="payment-card"
          v-if="!loading && !isFreeBooking && !requirePaymentMethod"
        >
          We’ll create and send you an invoice
        </div>

        <div class="payment-card" v-if="!loading && isFreeBooking">
          This booking is free
        </div>

        <base-button
          class="next-button"
          v-if="!loading"
          @click="next()"
          :processing="processing"
          >Confirm booking</base-button
        >
      </o-flex>
      <o-flex v-if="$vuetify.breakpoint.mdAndUp" class="right-side">
        <booking-side-card :booking="selectedBooking" />
      </o-flex>
    </o-layout>
  </layout-content>
</template>
<script>
import loader from "../assets/loader.svg";
import { mapState, mapGetters, mapMutations } from "vuex";
import OptixV1Service from "../services/OptixV1Service";
import Validate from "optixapp-ui-kit/src/vue/mixins/Validate";
import OptixV2Service from "../services/OptixV2Service";

export default {
  mixins: [Validate],
  data() {
    return {
      loader,
      processing: false,
      processingBookingCreation: false,
      loading: true,
      form: {
        payment_method_id: 0
      },
      availablePaymentMethods: [],
      availableRetainableMethods: [],
    };
  },
  computed: {
    ...mapState(["user", "lookup", "organization"]),
    ...mapGetters(["selectedBooking", "paymentGateway"]),
    requirePaymentMethod() {
      return this.paymentGateway.processor != "none";
    },
    isFreeBooking() {
      return this.selectedBooking.total == 0;
    },
    paymentMethods() {
      return this.availablePaymentMethods;
    },
    bookingInput() {
      const pm_id =
        !this.isFreeBooking &&
        this.requirePaymentMethod &&
        this.form.payment_method_id != 0 && this.form.payment_method_id
          ? this.form.payment_method_id
          : null;

      return {
        member_id: this.lookup.member_account,
        user_id: this.lookup.user_account,
        resource_id: this.selectedBooking.resource.resource_id,
        start_timestamp: parseInt(
          this.selectedBooking.period.start.toSeconds()
        ),
        charge:
          !this.isFreeBooking && this.requirePaymentMethod ? "NOW" : "TODAY",
        end_timestamp: parseInt(this.selectedBooking.period.end.toSeconds()),
        pm_id,
      };
    },
  },
  mounted() {
    if (!this.selectedBooking.resource.resource_id) {
      this.$router.push({ name: "find" });
      return;
    }
    if (!this.lookup.member_account) {
      this.$router.push({ name: "auth" });
      return;
    }
    let promises = [];
    promises.push(OptixV1Service.getPaymentMethods(this.lookup.member_account));
    promises.push(OptixV2Service.bookingDraft(this.bookingInput));
    promises.push(
      OptixV2Service.retainablePaymentMethods({
        organization_id: this.organization.organization_id,
      })
    );
    Promise.all(promises)
      .then((responses) => {
        let payment_methods = responses[0];
        this.availablePaymentMethods =
          payment_methods.data.response.payment_methods;
        let defaultPaymentMethod = this.availablePaymentMethods.filter(
          (method) => method.is_default == 1
        )[0];
        this.form.payment_method_id = defaultPaymentMethod
          ? defaultPaymentMethod.pm_id
          : 0;
        this.setDraftedBooking(responses[1].data.data.bookingsDraft);
        this.availableRetainableMethods =
          responses[2].data.data.retainablePaymentMethods.filter(
            (retainable) => retainable.open_using_external_app
          );
        this.loading = false;
      })
      .catch(() => {
        //window.console.log("Payment failure", response);
      });
  },
  methods: {
    ...mapMutations([
      "setDraftedBooking",
      "setCommittedBooking",
      "appendSnackbarError",
    ]),
    successHandler(card) {
      this.availablePaymentMethods.push(card);
      this.form.payment_method_id = card.pm_id;
    },
    errorHandler(e) {
      this.processing = false;
      if (e && e.errors && e.errors[0] && e.errors[0].message) {
        this.appendSnackbarError({ message: e.errors[0].message });
      }
    },
    next() {
      this.processing = true;
      let promises = [
        // First is payment method check
      ];
      if (
        !this.isFreeBooking &&
        this.requirePaymentMethod &&
        this.form.payment_method_id == 0
      ) {
        promises.push(this.$refs.newPaymentOption.processCard());
      } else {
        promises.push(true);
      }

      Promise.all(promises)
        .then((responses) => {
          if (!responses[0] || responses[0].errors) {
            this.processing = false;
            return;
          }
          this.processingBookingCreation = true;
          OptixV2Service.bookingCommit(this.bookingInput)
            .then((booking) => {
              if (booking.data.errors && booking.data.errors.length > 0) {
                //window.console.log(booking.data.errors);
                let error = booking.data.errors[0].message.replace(
                  /^bookings\[0\]: /,
                  ""
                );
                if (error == "Resource is not available.") {
                  error =
                    "This resource is no longer available at this time, please try to find an available time or another resource";
                  this.appendSnackbarError({ message: error });
                  this.$router.push({
                    name: "resource",
                    params: {
                      resource_id: this.selectedBooking.resource.resource_id,
                    },
                  });
                } else {
                  this.appendSnackbarError({ message: error });
                }
                this.processingBookingCreation = false;
                this.processing = false;
              } else {
                if (booking.data.data.bookingsCommit.bookings[0].is_canceled) {
                  this.appendSnackbarError({
                    message:
                      booking.data.data.bookingsCommit.cancellation_reason ||
                      "Error creating your reservation, please try another payment method.",
                  });
                  this.processingBookingCreation = false;
                  this.processing = false;
                } else {
                  this.setCommittedBooking(booking.data.data.bookingsCommit);
                  OptixV2Service.getBookingLink(
                    booking.data.data.bookingsCommit.bookings[0].booking_id
                  )
                    .then((response) => {
                      // Get JWT token and redirect to the confirmation page
                      let confirmation = response.data.data.booking.web_link
                        .replace(/^.+\/confirmation\//, "")
                        .split("/");
                      this.appendSnackbarError({
                        type: "success",
                        message: "Booking confirmed!",
                      });
                      this.$gtag.purchase({
                        transaction_id: "booking_" + confirmation[0],
                        affiliation: this.organization.organization_id,
                        value: this.selectedBooking.amount,
                        tax: this.selectedBooking.tax,
                        items: [
                          {
                            id: this.selectedBooking.resource.resource_id,
                            name: this.selectedBooking.resource.name,
                            quantity:
                              (this.selectedBooking.period.end.toSeconds() -
                                this.selectedBooking.period.start.toSeconds()) /
                              60 /
                              60,
                            price: this.selectedBooking.amount,
                          },
                        ],
                      });
                      this.$router.push({
                        name: "confirmation",
                        params: {
                          jwt_token: confirmation[1],
                          booking_id: confirmation[0],
                        },
                      });
                    })
                    .catch(() => {
                      this.processingBookingCreation = false;
                      this.processing = false;
                      //window.console.log(e);
                    });
                }
              }
            })
            .catch(() => {
              this.processingBookingCreation = false;
              this.processing = false;
              //window.console.log(e);
            });
        })
        .catch(() => {
          this.processing = false;
          //window.console.log(e);
        });
    },
  },
};
</script>
<style scoped>
.payment-card {
  background: rgba(var(--optix-theme-oppositeBorderRGBColor), 0.16);
  border-radius: 4px;
  padding: 16px 16px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 24px;
  margin-bottom: 24px;
}
</style>
