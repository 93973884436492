<template>
  <div class="card clickable" @click="select">
    <o-radio :value="paymentMethod.pm_id" ref="rad" :disabled="disabled" />
    <div class="name-card" v-if="paymentMethod.last_four_digits">
      {{ cardType }} ending {{ paymentMethod.last_four_digits }}
    </div>
    <div class="name-card" v-else>
      {{ cardType }}{{ paymentMethod.account_type }}
    </div>
    <div class="image" v-if="cardIcon">
      <img :src="cardIcon" />
    </div>
  </div>
</template>
<script>
import Cards from "../assets/cards";
import upperFirst from "lodash/upperFirst";
import camelCase from "lodash/camelCase";

export default {
  props: {
    paymentMethod: {
      default: {},
    },
    disabled: {
      default: false,
    },
  },
  data() {
    return {
      cards: Cards,
    };
  },
  mounted() {},
  computed: {
    cardType() {
      return upperFirst(this.paymentMethod.card_type);
    },
    cardIcon() {
      const dark = this.$optix.theme.darkMode ? "Dark" : "";
      let name = upperFirst(camelCase(this.paymentMethod.card_type + dark));
      return this.cards[name] ?? this.cards["Bank" + dark];
    },
  },
  methods: {
    select() {
      this.$refs.rad.$refs.input.click();
    },
  },
};
</script>
<style scoped>
.card {
  border: var(--outline-dark-border);
  border-radius: 4px;
  padding: 16px 16px 16px;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  color: var(--optix-theme-defaultFontColor);
}
.card .v-radio {
  padding: 0px 8px;
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.name-card {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.image {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>
