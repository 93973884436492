<template>
  <layout-content class="confirmation" :class="{ outlined: isOutlined }">
    <o-layout column class="base-box" v-if="loading">
      <o-flex class="header loader-container">
        <loader />
      </o-flex>
    </o-layout>
    <o-layout
      column
      class="base-box"
      v-if="!loading"
      :class="{ detached: !isOutlined }"
    >
      <o-flex class="main-box">
        <div class="title">
          <div>
            <v-img
              v-if="picture"
              key="123"
              :aspect-ratio="1"
              :src="picture"
              width="64"
            />
          </div>
          <div class="title" v-if="!tourContext.is_canceled">
            Tour confirmed
          </div>
          <div class="title" v-if="tourContext.is_canceled">Tour canceled</div>
        </div>
        <div>
          <location-time-block
            :location="tourContext.location"
            :start="tourContext.start_timestamp"
            :end="tourContext.end_timestamp"
          />
          <div class="actions-menu" v-if="!tourContext.is_canceled">
            <div>
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <a v-on="on">Add to calendar</a>
                </template>
                <v-list>
                  <v-list-item @click="ExternalCalendars.openICal(tourContext)">
                    <v-list-item-title>iCal</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="ExternalCalendars.openICal(tourContext)">
                    <v-list-item-title>Outlook</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    @click="ExternalCalendars.openGoogleCal(tourContext)"
                  >
                    <v-list-item-title>Google</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
            <div v-if="editable">
              <v-dialog v-model="cancellationDialog" width="500">
                <template v-slot:activator="{ on }">
                  <a v-on="on">Cancel tour</a>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="headline">Cancel tour</span>
                  </v-card-title>
                  <v-card-text
                    >Are you sure you want to cancel this tour?</v-card-text
                  >
                  <v-card-actions>
                    <v-spacer />
                    <o-btn
                      class="text-button"
                      text
                      @click="cancellationDialog = false"
                      >Close</o-btn
                    >
                    <o-btn class="text-button" text @click="cancelBooking()"
                      >Cancel tour</o-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </div>
        </div>
        <div class="location">
          <h5 class="mb-3">
            <o-btn class="text-button" text @click="gotoMap"
              >Get directions</o-btn
            >Getting here
          </h5>
          <p v-if="tourContext.location.directions">
            {{ tourContext.location.directions }}
          </p>
          <a :href="locationMapLink" target="_blank">
            <img
              v-if="locationMapUrl()"
              :src="locationMapUrl()"
              :srcset="locationMapUrl(2) + ' 2x,' + locationMapUrl(3) + ' 3x'"
            />
          </a>
          <p class="address">
            <address-description :location="tourContext.location" />
          </p>
        </div>
        <div class="address">
          <div>{{ organization.name }}</div>
          <div>
            <a :href="'mailto:' + tourContext.location.contact.email">{{
              tourContext.location.contact.email
            }}</a>
          </div>
          <div>
            <a :href="'tel:' + tourContext.location.phone">{{
              tourContext.location.phone
            }}</a>
          </div>
        </div>
      </o-flex>
    </o-layout>
  </layout-content>
</template>
<script>
import loader from "../assets/loader.svg";
import { DateTime } from "luxon";
import { mapState, mapMutations } from "vuex";
import queryString from "query-string";
import OptixV2Service from "../services/OptixV2Service";
import ExternalCalendars from "../services/ExternalCalendars";
export default {
  data() {
    return {
      ExternalCalendars,
      loader,
      booking: {},
      loading: true,
      cancellationDialog: false,
    };
  },
  mounted() {
    this.loadBooking();
    if (this.$optix.env.get.open == "cancel") {
      this.cancellationDialog = true;
    }
  },
  computed: {
    ...mapState(["tourContext", "organization", "mode"]),
    isOutlined() {
      return (
        this.mode == "modal" ||
        this.mode == "embed" ||
        this.$vuetify.breakpoint.width < 950
      );
    },
    editable() {
      if (!this.tourContext) {
        return false;
      }
      return !this.tourContext.is_canceled;
    },
    picture() {
      if (
        this.tourContext.location.pictures &&
        this.tourContext.location.pictures[0] &&
        this.tourContext.location.pictures[0].large
      ) {
        return this.tourContext.location.pictures[0].large;
      }
      if (this.locationMapUrl(1)) {
        return this.locationMapUrl(1);
      }
      return false;
    },
    locationMapLink() {
      if (
        this.tourContext.location.latitude &&
        this.tourContext.location.longitude
      ) {
        return (
          "https://www.google.com/maps/place/" +
          this.tourContext.location.latitude +
          "," +
          this.tourContext.location.longitude
        );
      }
      return null;
    },
    period() {
      return {
        start: DateTime.fromSeconds(this.tourContext.start_timestamp, {
          zone: this.tourContext.location.timezone,
        }),
        end: DateTime.fromSeconds(this.tourContext.end_timestamp, {
          zone: this.tourContext.location.timezone,
        }),
      };
    },
    duration() {
      return this.tourContext.end_timestamp - this.tourContext.start_timestamp;
    },
  },
  methods: {
    ...mapMutations([
      "appendSnackbarError",
      "selectLocation",
      "setCommittedTour",
    ]),
    loadBooking() {
      this.loading = true;
      OptixV2Service.getTourUsingJWT(this.$route.params.jwt_token).then(
        (response) => {
          this.setCommittedTour(response.data.data.tour);
          this.selectLocation(response.data.data.tour.location);
          this.loading = false;
        }
      );
    },
    cancelBooking() {
      this.loading = true;
      this.cancellationDialog = false;
      OptixV2Service.cancelTourUsingJWT(this.$route.params.jwt_token).then(
        () => {
          this.appendSnackbarError({
            type: "success",
            message: "Tour canceled!",
          });
          this.loadBooking();
        }
      );
    },
    locationMapUrl(scale) {
      if (
        this.tourContext.location.latitude &&
        this.tourContext.location.longitude
      ) {
        let icon =
          "icon:https://s3-us-west-2.amazonaws.com/s3.sharedesk.net/assets/maps/asset-icons-map-pin-selected.png";
        let googleScale = 1;
        switch (scale) {
          case 2:
            googleScale = 2;
            icon =
              "scale:2|icon:https://s3-us-west-2.amazonaws.com/s3.sharedesk.net/assets/maps/asset-icons-map-pin-selected_2x.png";
            break;
          case 3:
            googleScale = 4;
            icon =
              "scale:4|icon:https://s3-us-west-2.amazonaws.com/s3.sharedesk.net/assets/maps/asset-icons-map-pin-selected_3x.png";
        }
        return (
          "https://maps.googleapis.com/maps/api/staticmap?" +
          queryString.stringify({
            key: this.$optix.env.getConf("google_key"),
            size: "640x260",
            zoom: 15,
            markers: `${icon}|${this.tourContext.location.latitude},${this.tourContext.location.longitude}`,
            scale: googleScale,
          })
        );
      }
      return null;
    },
    formatTime(datetime) {
      return datetime.toLocaleString(DateTime.TIME_SIMPLE);
    },
    gotoMap() {
      window.open(this.locationMapLink);
    },
  },
};
</script>
<style scoped>
.base-box {
  align-items: center;
  align-self: center;
}
.base-box.detached {
  max-width: 660px !important;
}
.base-box:not(.detached) > div {
  max-width: 700px !important;
}
.base-box .main-box {
  width: 100%;
  margin: 0px;
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.outlined .layout-content {
  display: flex;
  justify-content: space-around !important;
  flex-direction: column;
}

.outlined .main-box {
  border-radius: 4px;
  margin-bottom: 48px;
}

@media (min-width: 550px) {
  .outlined .main-box {
    border: var(--outline-dark-border);
  }
}

.main-box > div {
  padding: 24px;
  border-bottom: var(--outline-dark-border);
  border-width: 1px;
}
.main-box > div:last-child {
  border-bottom: 0;
}
.main-box > .title {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.main-box > .title .v-image {
  border-radius: 4px;
}
.main-box > .title > .title {
  flex-grow: 1;
  padding-left: 24px;
  font-weight: 500;
  font-size: 22px !important;
  line-height: 24px;
}
.location img {
  max-width: 100%;
  margin: 16px 0px;
  border-radius: 4px;
}
.location .address {
  font-size: 14px;
  margin-top: -8px;
}
.location button {
  float: right;
  color: var(--optix-theme-accentColor);
  font-weight: 500;
}
h1 {
  font-size: 32px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 35px;
}
h4 {
  opacity: 0.87;
  font-size: 16.16px;
  letter-spacing: 0.5px;
  line-height: 28px;
  font-weight: normal;
  margin-bottom: 8px;
}
h4 .o-icon {
  width: 24px;
  vertical-align: middle;
  margin-top: -3px;
}
h4 .o-icon:first-child {
  margin-right: 12px;
}
h5 {
  font-weight: 500;
}
h5 button {
  font-weight: 500;
}
.actions-menu {
  display: flex;
  flex-direction: column;
  margin-top: 36px;
}
.actions-menu > div:last-child {
  padding-bottom: 0px;
}
.cost > h5 {
  padding-bottom: 4px;
  font-size: 22px;
  letter-spacing: 0;
  line-height: 24px;
}
.actions-menu > div {
  border-top: var(--outline-dark-border);
  border-width: 1px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px 0px;
}

.main-box > div.address {
  padding: 48px 0px;
  display: flex;
  flex-direction: column;
  align-content: center;
  text-align: center;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
}
.main-box > div.address a {
  color: rgba(var(--optix-theme-defaultFontRGBColor), 0.54);
}
.address > div:first-child {
  font-weight: 500;
}
.o-icon {
  font-size: 24px;
  line-height: 24px;
}
</style>
