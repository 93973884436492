<template>
  <div>
    <o-layout row align-center class="nowrap">
      <o-flex class="oneline mr-2">
        <o-text-field
          :disabled="disabled"
          label="First name"
          :error-messages="validateErrors.firstName"
          v-model="firstName"
        />
      </o-flex>
      <o-flex class="oneline">
        <o-text-field
          :disabled="disabled"
          label="Last name"
          :error-messages="validateErrors.lastName"
          v-model="lastName"
        />
      </o-flex>
    </o-layout>
    <o-layout row align-center>
      <o-flex class="card">
        <div id="StripeCC"></div>
      </o-flex>
    </o-layout>
  </div>
</template>
<script>
import Validate from "optixapp-ui-kit/src/vue/mixins/Validate";
import OptixV2Service from "../services/OptixV2Service";
import _get from "lodash/get";
import { mapState } from "vuex";

export default {
  mixins: [Validate],
  props: {
    disabled: {
      default: false,
    },
  },
  data() {
    return {
      firstName: "",
      lastName: "",
      stripe: null,
      card: null,
    };
  },
  mounted() {
    this.firstName = this.user.name;
    this.lastName = this.user.surname;

    // eslint-disable-next-line no-undef
    this.stripe = Stripe(this.organization.payment_gateway.publishable_api_key);

    let elements = this.stripe.elements({
      fonts: [
        {
          cssSrc: "https://fonts.googleapis.com/css?family=Roboto",
        },
      ],
      locale: "auto",
    });

    var elementStyles = {
      base: {
        color: "#32325D",
        fontFamily: "Roboto",
        fontSize: "16px",
        fontSmoothing: "antialiased",
        "::placeholder": {
          color: "#333",
        },
        ":-webkit-autofill": {
          color: "#e39f48",
        },
      },
      invalid: {
        color: "#E25950",
        "::placeholder": {
          color: "#FFCCA5",
        },
      },
    };
    var elementClasses = {
      focus: "focused",
      empty: "empty",
      invalid: "invalid",
    };
    this.card = elements.create("card", {
      style: elementStyles,
      classes: elementClasses,
    });
    this.card.mount("#StripeCC");
  },
  computed: {
    ...mapState(["user", "organization", "lookup"]),
  },
  methods: {
    validateInfo() {
      let validations = {
        firstName: [Validate.rules.required],
        lastName: [Validate.rules.required],
      };
      return this.validateForm(this, validations);
    },
    processCard() {
      // Stripe
      return new Promise((accept, reject) => {
        this.validateInfo()
          .then(() => {
            OptixV2Service.startSetupPaymentMethod()
              .then((setupIntent) => {
                let setupIntentSecret = _get(
                  setupIntent,
                  "data.data.startSetupPaymentMethod.secret"
                );
                this.stripe
                  .handleCardSetup(setupIntentSecret, this.card, {
                    payment_method_data: {
                      billing_details: {
                        name: this.firstName + " " + this.lastName,
                      },
                    },
                  })
                  .then((result) => {
                    if (
                      result.setupIntent &&
                      result.setupIntent.payment_method
                    ) {
                      let variables = {
                        member_id: this.lookup.member_account,
                        organization_id: this.organization.organization_id,
                        payment_method_id: result.setupIntent.payment_method,
                        setup_intent_id: result.setupIntent.id,
                      };
                      OptixV2Service.completeSetupPaymentMethod(variables)
                        .then((paymentMethodResult) => {
                          let result = _get(
                            paymentMethodResult,
                            "data.data.completeSetupPaymentMethod"
                          );
                          let success = {
                            pm_id: result.id,
                            card_type: result.card_brand,
                            last_four_digits: result.card_last_four_digits,
                          };
                          this.$emit("success", success);
                          accept(success);
                        })
                        .catch((error) => {
                          this.$emit("error", error);
                          reject(error);
                        });
                    } else {
                      this.$emit("error", result.error);
                      reject(result.error);
                    }
                  })
                  .catch((error) => {
                    this.$emit("error", error);
                    reject(error);
                  });
              })
              .catch((error) => {
                this.$emit("error", error);
                reject(error);
              });

            // // eslint-disable-next-line no-undef
            // Stripe.setPublishableKey(
            //   this.$optix.env.getConf("stripe_public_key")
            // );
            // // eslint-disable-next-line no-undef
            // Stripe.card.createToken(
            //   {
            //     name: this.firstName + " " + this.lastName,
            //     address_zip: this.zip,
            //     number: this.cardNumber,
            //     cvc: this.cvc,
            //     exp_month: this.expireMonth,
            //     exp_year:
            //       this.expireYear < 2000
            //         ? parseFloat(this.expireYear) + 2000
            //         : this.expireYear
            //   },
            //   (status, res) => {
            //     if (res.error) {
            //       let messages = this.collectStripeErrors(res.error);
            //       let errors = {
            //         errors: [res.error],
            //         messages
            //       };
            //       this.$emit("error", errors);
            //       reject(errors);
            //     } else {
            //       let acceptObject = {
            //         details: [],
            //         venue_id: this.organization.organization_id,
            //         processor_id: 1, // Stripe
            //         token_id: res.id,
            //         token: res,
            //         type_id: 1
            //       };
            //       acceptObject.member_id = this.lookup.member_account;
            //       OptixV1Service.addPaymentMethod(acceptObject)
            //         .then(success => {
            //           this.$emit("success", success.data.response);
            //           this.number = "";
            //           this.verification_value = "";
            //           this.month = "";
            //           this.year = "";
            //           this.zip = "";
            //           accept(success.data.response);
            //         })
            //         .catch(error => {
            //           let errors = {
            //             errors: error,
            //             messages: [
            //               "Error adding payment method, please try again."
            //             ]
            //           };
            //           this.$emit("error", errors);
            //           reject(errors);
            //         });
            //     }
            //   }
            // );
          })
          .catch((error) => {
            let errors = {
              errors: error,
              messages: ["Error adding payment method, please try again."],
            };
            this.$emit("error", errors);
            reject(errors);
          });
      });
    },
  },
};
</script>
<style scoped>
.card {
  padding: 16px;
}
</style>
