<template>
  <div
    class="location-time-block address"
    :class="{ 'icon-margin': iconMargin }"
  >
    <h4>
      <o-icon icon="o-icon-location-pin" />
      <address-description :location="location" />
    </h4>
    <h4>
      <o-icon icon="o-icon-calendar" />
      <span>{{ localeDate }}</span>
    </h4>
    <h4>
      <o-icon icon="o-icon-clock" />
      <span class="time">
        {{ localeStart }}
        <o-icon icon="o-icon-arrow-right-feather" />
        {{ localeEnd }}
      </span>
    </h4>
  </div>
</template>
<script>
import { DateTime } from "luxon";
export default {
  props: {
    location: {
      default: null,
    },
    start: {
      default: null,
    },
    end: {
      default: null,
    },
    iconMargin: {
      default: true,
    },
  },
  computed: {
    localeDate() {
      if (!this.start || !this.location) {
        return false;
      }
      return DateTime.fromSeconds(this.start, {
        zone: this.location.timezone,
      }).toFormat("EEEE, MMM d, yyyy");
    },
    localeStart() {
      if (!this.start || !this.location) {
        return false;
      }
      return DateTime.fromSeconds(this.start, {
        zone: this.location.timezone,
      }).toLocaleString(DateTime.TIME_SIMPLE);
    },
    localeEnd() {
      if (!this.end || !this.location) {
        return false;
      }
      return DateTime.fromSeconds(this.end, {
        zone: this.location.timezone,
      }).toLocaleString(DateTime.TIME_SIMPLE);
    },
  },
};
</script>
<style scoped>
.address > * {
  margin-bottom: 7px;
  font-size: 16px;
  letter-spacing: 0.2px;
  line-height: 28px;
  font-weight: 400;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.address > * > .o-icon {
  margin-top: 2px;
}
.time .o-icon {
  margin-top: -3px;
}
@media (min-width: 801px) {
  .address {
    margin-bottom: 24px;
  }
}
.address > * > .o-icon:first-child {
  margin-right: 8px;
}
.address.icon-margin > * > .o-icon:first-child {
  margin-right: 16px;
}
.o-icon {
  font-size: 24px;
  opacity: 0.87;
}
</style>
