<template>
  <div style="white-space: pre-wrap">
    <div
      class="btn clickable"
      @click="open = !open"
      v-if="shortVersion != this.description"
    >
      <v-icon v-if="!open">mdi-chevron-down</v-icon>
      <v-icon v-if="open">mdi-chevron-up</v-icon>
    </div>
    <span>{{ this.descriptionContent }}</span>
  </div>
</template>
<script>
export default {
  props: {
    description: {
      default: null,
    },
    maxLength: {
      default: 150,
    },
  },
  data() {
    return {
      open: false,
    };
  },
  computed: {
    descriptionContent() {
      if (this.open) {
        return this.description;
      } else {
        return this.shortVersion;
      }
    },
    shortVersion() {
      if (!this.description) {
        return "";
      }
      let short = this.description.trim().substr(0, this.maxLength);
      if (short != this.description) {
        short += "...";
      }
      return short;
    },
  },
};
</script>
<style scoped>
.btn {
  float: right;
}
</style>
