<template>
  <span>
    <span v-if="location.unit">{{ location.unit }}, </span>
    <span v-if="location.address">{{ location.address }}, </span>
    <span v-if="location.city">{{ location.city }}, </span>
    {{ location.country }}
  </span>
</template>
<script>
export default {
  props: {
    location: {
      default: null,
    },
  },
};
</script>
