<template>
  <v-dialog v-model="isOpen" width="600">
    <v-card>
      <v-card-title>
        <span class="headline">{{ title }}</span>
      </v-card-title>
      <v-card-text
        :class="textClasses"
        class="modal-custom-text"
        v-html="filteredText"
      ></v-card-text>
      <v-card-actions>
        <v-spacer />
        <o-btn class="text-button" text @click="close">{{ closeText }}</o-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    value: {
      default: false,
    },
    title: {
      default: false,
    },
    text: {
      default: false,
    },
    closeText: {
      default: "Close",
    },
    pre: {
      default: false,
    },
  },
  computed: {
    isOpen: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
    textClasses() {
      return this.pre ? ["modal-pre"] : [];
    },
    filteredText() {
      let text = this.text;
      // TODO Avoid script injection (Not really safe =/)
      return text;
    },
  },
  methods: {
    close() {
      this.isOpen = false;
    },
  },
};
</script>
<style>
.modal-pre {
  white-space: pre-line;
}
.modal-custom-text h1 {
  font-size: 18px !important;
}
.modal-custom-text h2 {
  font-size: 16px !important;
}
.modal-custom-text h3 {
  font-size: 14px !important;
}
</style>
