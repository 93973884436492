<template>
  <layout-content>
    <o-layout row>
      <o-flex class="left-side">
        <o-text-field label="Email" v-model="user.email" :disabled="true" />
        <o-text-field
          type="password"
          label="Password"
          ref="password"
          v-model="form.password"
          :error-messages="validateErrors.password"
          @keydown.enter.prevent="next()"
        />
        <base-button
          class="mt-5 next-button"
          @click="next()"
          :processing="processing"
          >Continue</base-button
        >
        <p class="recover">
          <a @click="recover">I don't know my password</a>
        </p>
      </o-flex>
      <o-flex v-if="$vuetify.breakpoint.mdAndUp" class="right-side">
        <booking-side-card :booking="selectedBooking" />
      </o-flex>
    </o-layout>
  </layout-content>
</template>
<script>
import { mapState, mapGetters, mapMutations } from "vuex";
import OptixV1Service from "../services/OptixV1Service";
import Validate from "optixapp-ui-kit/src/vue/mixins/Validate";

export default {
  mixins: [Validate],
  data() {
    return {
      processing: false,
      form: {
        password: "",
      },
    };
  },
  computed: {
    ...mapState(["user", "lookup"]),
    ...mapGetters(["selectedBooking"]),
  },
  mounted() {
    if (!this.selectedBooking.resource.resource_id) {
      this.$router.push({ name: "find" });
      return;
    }
    if (!this.lookup.member_account) {
      this.$router.push({ name: "auth" });
      return;
    }
    this.$refs.password.focus();
  },
  methods: {
    ...mapMutations(["setUser"]),
    recover() {
      this.$router.replace({ name: "passwordRecovery" });
    },
    next() {
      this.validateForm(this.form, {
        password: [Validate.rules.password],
      })
        .then(() => {
          this.processing = true;
          OptixV1Service.login({ ...this.form, email: this.user.email })
            .then((success) => {
              if (typeof success.data.response === "string") {
                this.addFormFieldError("password", success.data.response);
              } else {
                this.setUser(success.data.response.user_data);
                this.$optix.env.access_token =
                  success.data.response.access_token;
                this.$router.replace({ name: "payment" });
              }
              this.processing = false;
            })
            .catch((e) => {
              this.addFormFieldError(
                "password",
                e.response.data.response
                  ? e.response.data.response
                  : "An error happened during the authentication"
              );
              this.processing = false;
            });
        })
        .catch(() => {});
    },
  },
};
</script>
<style scoped>
.left-side p {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.2px;
  line-height: 28px;
}
.recover {
  margin-top: 8px;
}
</style>
