/**
 * Handy functions for time manipulation
 */

/**
 * Align timestamp to the 15 minutes grid (align up)
 * @param {integer} timestamp
 */
export function alignSeconds(timestamp) {
  return Math.ceil(Math.floor(timestamp) / (15 * 60)) * 15 * 60;
}
