<template>
  <!-- <svg
    width="28px"
    height="27px"
    viewBox="0 0 28 27"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>Location graphic</title>
    <desc>Created with Sketch.</desc>
    <defs>
      <path
        d="M12,-1.77635684e-15 C17.5228475,-1.77635684e-15 22,4.47715246 22,10 C22,13.3716046 20.1975984,16.6760075 17.2259527,19.8127446 C16.2070021,20.8883036 15.118253,21.8681778 14.0291768,22.7354051 C13.8382877,22.8874094 13.6540369,23.0305855 13.4775129,23.1646462 L13.4775129,23.1646462 L12.9722093,23.5391949 L12.5547002,23.8320503 C12.2188008,24.0559832 11.7811992,24.0559832 11.4452998,23.8320503 L11.4452998,23.8320503 L11.0277907,23.5391949 C10.7078263,23.3081095 10.3526014,23.0394137 9.97082322,22.7354051 C8.88174698,21.8681778 7.7929979,20.8883036 6.77404732,19.8127446 C3.80240157,16.6760075 2,13.3716046 2,10 C2,4.47715246 6.47715251,-1.77635684e-15 12,-1.77635684e-15 Z M12,2 C7.58172201,2 4,5.58172197 4,10 C4,12.7533954 5.57259843,15.6364925 8.22595268,18.4372554 C9.1757521,19.4398214 10.196378,20.3583847 11.2166768,21.1708449 C11.3949283,21.3127859 11.5666346,21.4462211 11.7306867,21.5708274 L11.7306867,21.5708274 L11.999,21.7719999 L12.5209935,21.3773367 L12.7833232,21.1708449 C13.803622,20.3583847 14.8242479,19.4398214 15.7740473,18.4372554 C18.4274016,15.6364925 20,12.7533954 20,10 C20,5.58172197 16.418278,2 12,2 Z M12,5.99999987 C14.209139,5.99999987 16,7.79086087 16,9.99999987 C16,12.2091389 14.209139,13.9999999 12,13.9999999 C9.790861,13.9999999 8,12.2091389 8,9.99999987 C8,7.79086087 9.790861,5.99999987 12,5.99999987 Z M12,7.99999987 C10.8954305,7.99999987 10,8.89543037 10,9.99999987 C10,11.1045694 10.8954305,11.9999999 12,11.9999999 C13.1045695,11.9999999 14,11.1045694 14,9.99999987 C14,8.89543037 13.1045695,7.99999987 12,7.99999987 Z"
        id="path-1"
      />
    </defs>
    <g id="Working" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Full-Screen---schedule---tour-link" transform="translate(-325.000000, -854.000000)">
        <g id="Tour" transform="translate(325.000000, 821.000000)">
          <g transform="translate(0.000000, 33.000000)" id="Location-graphic">
            <g>
              <rect
                id="Rectangle"
                :fill="color"
                opacity="0.200000003"
                x="3"
                y="23"
                width="24"
                height="4"
                rx="2"
              />
              <g id="Icon-/-Location-pin" transform="translate(3.000000, 1.000000)">
                <mask id="mask-2" fill="white">
                  <use xlink:href="#path-1" />
                </mask>
                <use id="Combined-Shape" fill="#000000" fill-rule="nonzero" xlink:href="#path-1" />
                <g
                  id="Colour-/-Primary-Purlple"
                  mask="url(#mask-2)"
                  :fill="color"
                  fill-rule="evenodd"
                >
                  <g
                    transform="translate(12.000000, 12.000000) scale(-1, 1) translate(-12.000000, -12.000000) "
                    id="Colour-/-Black"
                  >
                    <rect x="0" y="0" width="24" height="24" />
                  </g>
                </g>
              </g>
              <circle id="Oval" fill-opacity="0.244618663" :fill="color" cx="1.5" cy="4.5" r="1.5" />
              <circle id="Oval" fill-opacity="0.244618663" :fill="color" cx="3" cy="15" r="1" />
              <circle id="Oval" fill-opacity="0.244618663" :fill="color" cx="26" cy="2" r="2" />
              <circle id="Oval" fill-opacity="0.244618663" :fill="color" cx="24" cy="20" r="1" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>-->
  <div class="tour-icon">
    <o-icon icon="o-icon-tour-b" />
    <o-icon icon="o-icon-tour-a" />
  </div>
</template>
<script>
export default {
  computed: {
    color() {
      return "#000";
    },
  },
};
</script>
<style scoped>
.tour-icon {
  position: relative;
  display: inline-block;
  width: 28px;
  height: 28px;
}
.tour-icon .o-icon {
  font-size: 28px;
  position: absolute;
  top: 0px;
  left: 0px;
  color: rgba(var(--optix-theme-accentRGBColor), 1);
}
.tour-icon .o-icon:first-child {
  color: rgba(var(--optix-theme-accentRGBColor), 0.2);
}
</style>
