<template>
  <layout-content>
    <o-layout column class="base-box" v-if="loading">
      <o-flex class="header loader-container">
        <loader />
      </o-flex>
    </o-layout>
    <o-layout column class="base-box" v-if="!loading">
      <o-flex class="header">
        <h1>{{ reservationStatus }}</h1>
        <p v-if="notificationByEmail">
          A confirmation email has been sent to {{ user.email }}
        </p>
      </o-flex>
      <o-flex class="main-box">
        <div class="title">
          <div>
            <v-img
              v-if="image"
              :aspect-ratio="1"
              :src="$optix.utils.resizedImage(image, 64)"
              width="64"
            />
          </div>
          <div class="title">{{ booking.resource.name }}</div>
          <div class="confirmation-code">Booking #{{ booking.booking_id }}</div>
        </div>
        <div>
          <location-time-block
            class="ltb"
            :location="booking.location"
            :start="this.booking.check_in_timestamp"
            :end="this.booking.check_out_timestamp"
          />
          <div
            class="actions-menu"
            v-if="!(booking.is_canceled || booking.is_completed)"
          >
            <div>
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <a v-on="on">Add to calendar</a>
                </template>
                <v-list>
                  <v-list-item @click="ExternalCalendars.openICal(booking)">
                    <v-list-item-title>iCal</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="ExternalCalendars.openICal(booking)">
                    <v-list-item-title>Outlook</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    @click="ExternalCalendars.openGoogleCal(booking)"
                  >
                    <v-list-item-title>Google</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
            <div v-if="editable && booking.is_approved">
              <v-dialog v-model="cancellationDialog" width="500">
                <template v-slot:activator="{ on }">
                  <a v-on="on">Cancel booking</a>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="headline">Cancel booking</span>
                  </v-card-title>
                  <v-card-text
                    >Are you sure you want to cancel this booking?</v-card-text
                  >
                  <v-card-actions>
                    <v-spacer />
                    <o-btn
                      class="text-button"
                      text
                      @click="cancellationDialog = false"
                      >Close</o-btn
                    >
                    <o-btn class="text-button" text @click="cancelBooking()"
                      >Cancel booking</o-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
            <div>
              <a @click="copyShareLink">Share</a>
            </div>
          </div>
        </div>
        <div class="location">
          <h5 class="mb-3">
            <o-btn class="text-button" text @click="gotoMap"
              >Get directions</o-btn
            >Getting here
          </h5>
          <p v-if="booking.location.directions">
            {{ booking.location.directions }}
          </p>
          <p v-if="booking.resource.directions">
            {{ booking.resource.directions }}
          </p>
          <a :href="locationMapLink" target="_blank">
            <img
              v-if="locationMapUrl()"
              :src="locationMapUrl()"
              :srcset="locationMapUrl(2) + ' 2x,' + locationMapUrl(3) + ' 3x'"
            />
          </a>
          <p class="address">
            <address-description :location="booking.location" />
          </p>
        </div>
        <!-- Cancellation policy -->
        <div
          class="cancellation-policy"
          v-if="
            organization.drop_in &&
            organization.drop_in.cancellation_policy &&
            editable
          "
        >
          <h5 class="mb-3">Cancellation policy</h5>
          <p>{{ organization.drop_in.cancellation_policy }}</p>
        </div>
        <!-- Cost -->
        <div class="cost" v-if="hasInvoices">
          <h5 class="mb-3">
            <o-btn
              v-if="hasInvoices"
              text
              @click="openInvoice"
              class="view-invoice-button text-button"
              >View Invoice</o-btn
            >Cost
            <v-menu v-model="invoiceMenu" attach=".view-invoice-button">
              <v-list>
                <v-list-item
                  v-for="(invoice, index) in booking.invoices"
                  :key="index"
                  @click="openSpecificInvoice(invoice)"
                >
                  <v-list-item-title>#{{ invoice.number }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </h5>
          <div>
            <span>
              {{ formattedPrice }} x
              <duration-description :duration="duration" :unit="durationUnit" />
            </span>
            <span>{{
              $optix.utils.currencyFormat(booking.invoices[0].items[0].subtotal)
            }}</span>
          </div>
          <div>
            <span>{{ taxLabel }}</span>
            <span>{{
              $optix.utils.currencyFormat(
                booking.invoices[0].items[0].tax ||
                  booking.invoices[0].items[0].included_tax
              )
            }}</span>
          </div>
          <div class="total">
            <span>Total</span>
            <span>{{
              $optix.utils.currencyFormat(booking.invoices[0].items[0].total)
            }}</span>
          </div>
        </div>
        <div class="address">
          <div>{{ organization.name }}</div>
          <div>
            <a :href="'mailto:' + booking.location.contact.email">{{
              booking.location.contact.email
            }}</a>
          </div>
          <div>
            <a :href="'tel:' + booking.location.phone">{{
              booking.location.phone
            }}</a>
          </div>
        </div>
      </o-flex>
    </o-layout>
  </layout-content>
</template>
<script>
import loader from "../assets/loader.svg";
import { DateTime } from "luxon";
import { mapState, mapMutations } from "vuex";
import queryString from "query-string";
import OptixV2Service from "../services/OptixV2Service";
import ExternalCalendars from "../services/ExternalCalendars";
export default {
  data() {
    return {
      ExternalCalendars,
      loader,
      booking: {},
      loading: true,
      invoiceMenu: false,
      cancellationDialog: false,
    };
  },
  mounted() {
    this.loadBooking();
    if (this.$optix.env.get.open == "cancel") {
      this.cancellationDialog = true;
    }
  },
  computed: {
    ...mapState(["committedBooking", "organization", "user"]),
    editable() {
      if (!this.booking) {
        return false;
      }
      return !!this.booking.web_link;
    },
    image() {
      if (!this.booking) {
        return false;
      }
      if (!this.booking.resource.images) {
        return false;
      }
      if (this.booking.resource.images.length === 0) {
        return false;
      }
      return this.booking.resource.images[0].url;
    },
    locationMapLink() {
      if (this.booking.location.latitude && this.booking.location.longitude) {
        return (
          "https://www.google.com/maps/place/" +
          this.booking.location.latitude +
          "," +
          this.booking.location.longitude
        );
      }
      return null;
    },
    reservationStatus() {
      if (this.booking) {
        if (this.booking.is_canceled) {
          return "Booking canceled";
        } else if (this.booking.is_rejected) {
          return "Booking rejected";
        } else {
          return "Booking confirmed";
        }
      }
      return "";
    },
    formattedPrice() {
      const netTotalAmount =
        this.booking.cost.total_amount - this.booking.cost.tax_amount;
      const resource_price = this.booking.cost.resource_price;

      const priceToFormat =
        this.durationUnit === "hour" ? resource_price : netTotalAmount;

      return this.$optix.utils.currencyFormat(priceToFormat);
    },
    duration() {
      return this.booking.check_out_timestamp - this.booking.check_in_timestamp;
    },
    durationUnit() {
      let period = this.booking.period.toLowerCase();

      const isHourlyBooking = period === "hour";
      const hoursDuration = this.duration / 3600;
      const projectedCost = hoursDuration * this.booking.cost.resource_price;
      const netTotalAmount =
        this.booking.cost.total_amount - this.booking.cost.tax_amount;

      if (isHourlyBooking && projectedCost > netTotalAmount) {
        period = "day";
      }

      return period;
    },
    hasInvoices() {
      return this.booking.invoices && this.booking.invoices.length > 0;
    },
    notificationByEmail() {
      return (
        this.committedBooking &&
        this.committedBooking.bookings &&
        this.committedBooking.bookings[0].booking_id ==
          this.booking.booking_id &&
        !this.booking.is_canceled
      );
    },
    taxLabel() {
      if (this.booking.tax_label) {
        return this.booking.tax_label;
      }

      let result = this.organization.features.tax_name || "Tax";
      if (this.booking.cost && this.booking.cost.inclusive_tax_rate > 0) {
        result = "Includes " + (result === "Tax" ? "tax" : result);
      }

      return result;
    },
  },
  methods: {
    ...mapMutations(["appendSnackbarError", "selectLocation"]),
    loadBooking() {
      this.loading = true;
      OptixV2Service.getBookingUsingJWT(this.$route.params.jwt_token).then(
        (response) => {
          this.booking = response.data.data.booking;
          this.selectLocation(response.data.data.booking.location);
          this.loading = false;
        }
      );
    },
    cancelBooking() {
      this.loading = true;
      this.cancellationDialog = false;
      OptixV2Service.cancelBookingUsingJWT(this.$route.params.jwt_token).then(
        (response) => {
          Object.assign(
            this.booking,
            response.data.data.bookingsCommit.bookings[0]
          );
          this.appendSnackbarError({
            type: "success",
            message: "Booking canceled!",
          });
          this.loadBooking();
        }
      );
    },
    locationMapUrl(scale) {
      if (this.booking.location.latitude && this.booking.location.longitude) {
        let icon =
          "icon:https://s3-us-west-2.amazonaws.com/s3.sharedesk.net/assets/maps/asset-icons-map-pin-selected.png";
        let googleScale = 1;
        switch (scale) {
          case 2:
            googleScale = 2;
            icon =
              "scale:2|icon:https://s3-us-west-2.amazonaws.com/s3.sharedesk.net/assets/maps/asset-icons-map-pin-selected_2x.png";
            break;
          case 3:
            googleScale = 4;
            icon =
              "scale:4|icon:https://s3-us-west-2.amazonaws.com/s3.sharedesk.net/assets/maps/asset-icons-map-pin-selected_3x.png";
        }
        return (
          "https://maps.googleapis.com/maps/api/staticmap?" +
          queryString.stringify({
            key: this.$optix.env.getConf("google_key"),
            size: "720x400",
            zoom: 15,
            markers: `${icon}|${this.booking.location.latitude},${this.booking.location.longitude}`,
            scale: googleScale,
          })
        );
      }
      return null;
    },
    formatTime(datetime) {
      return datetime.toLocaleString(DateTime.TIME_SIMPLE);
    },
    gotoMap() {
      window.open(this.locationMapLink);
    },
    openInvoice() {
      if (!this.booking.invoices) {
        return false;
      }
      if (this.booking.invoices.length == 1) {
        this.openSpecificInvoice(this.booking.invoices[0]);
      } else {
        this.invoiceMenu = true;
      }
    },
    openSpecificInvoice(invoice) {
      window.open(invoice.web_link);
    },
    copyShareLink() {
      this.$copyText(this.booking.shareable_link);
      this.appendSnackbarError({
        type: "success",
        message: "Link copied to clipboard",
      });
    },
  },
};
</script>
<style scoped>
.header {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.header h1 {
  margin-bottom: 48px;
  font-weight: 500;
}
@media (max-width: 800px) and (min-width: 501px) {
  .header h1 {
    margin-top: 48px;
  }
}
.header p {
  margin-top: -20px;
  margin-bottom: 42px;
}
.base-box {
  align-items: center;
}
.base-box > div {
  width: 700px;
}
.main-box {
  border: var(--outline-dark-border);
  border-radius: 4px;
  margin-bottom: 48px;
}
.main-box > div {
  padding: 24px;
  border-bottom: var(--outline-dark-border);
  border-width: 1px;
}
@media (max-width: 550px) {
  .main-box {
    border: 0px;
  }
  .main-box > div {
    padding-left: 0px;
    padding-right: 0px;
  }
  .header h1 {
    margin-top: 24px;
    margin-bottom: 24px;
  }
}
.main-box > div:last-child {
  border-bottom: 0;
}
.main-box > .title {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.main-box > .title .v-image {
  border-radius: 4px;
}
.main-box > .title > .title {
  flex-grow: 1;
  padding-left: 24px;
  font-weight: 500;
  font-size: 22px !important;
  line-height: 24px;
}
.location img {
  max-width: 100%;
  margin: 16px 0px;
  border-radius: 4px;
}
.location .address {
  font-size: 14px;
  margin-top: -8px;
}
.location button,
.cost button {
  float: right;
  color: var(--optix-theme-accentColor);
  font-weight: 500;
}
.confirmation-code {
  opacity: 0.38;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 18px;
  font-weight: 400;
}
h1 {
  font-size: 32px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 35px;
}
h4 {
  opacity: 0.87;
  font-size: 16.16px;
  letter-spacing: 0.5px;
  line-height: 28px;
  font-weight: normal;
  margin-bottom: 8px;
}
h4 .o-icon {
  width: 24px;
  vertical-align: middle;
  margin-top: -3px;
}
h4 .o-icon:first-child {
  margin-right: 12px;
}
h5 {
  font-weight: 500;
}
h5 button {
  font-weight: 500;
}
.actions-menu {
  display: flex;
  flex-direction: column;
  margin-top: 36px;
}
.actions-menu > div:last-child {
  padding-bottom: 0px;
}
.cost > h5 {
  padding-bottom: 4px;
  font-size: 22px;
  letter-spacing: 0;
  line-height: 24px;
}
.cost button {
  margin-top: -4px;
}
.cost > div,
.actions-menu > div {
  border-top: var(--outline-dark-border);
  border-width: 1px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px 0px;
}
.cost > div.total {
  font-weight: 500;
  font-size: 20px;
  letter-spacing: 0.25px;
  line-height: 22px;
  padding-bottom: 0px;
}

.main-box > div.address {
  padding: 48px 0px;
  display: flex;
  flex-direction: column;
  align-content: center;
  text-align: center;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
}
.main-box > div.address a {
  color: rgba(var(--optix-theme-defaultFontRGBColor), 0.54);
}
.address > div:first-child {
  font-weight: 500;
}
.o-icon {
  font-size: 24px;
  line-height: 24px;
}
</style>
