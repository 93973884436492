<template>
  <layout-content>
    <o-layout>
      <div class="left-side" v-if="sideBySide">
        <location-time-block
          :location="tourContext.location"
          :start="tourContext.start_timestamp"
          :end="tourContext.end_timestamp"
        />
      </div>
      <div
        class="right-side"
        :class="{
          fullsize: !sideBySide,
          'no-margin': mode == 'modal' || mode == 'embed',
        }"
      >
        <location-time-block
          v-if="!sideBySide"
          :location="tourContext.location"
          :start="tourContext.start_timestamp"
          :end="tourContext.end_timestamp"
        />
        <h2 class="mb-4" :class="{ 'mt-4': !sideBySide }">Your details</h2>
        <div class="form">
          <div class="line">
            <o-text-field
              label="First name"
              v-model="form.name"
              :error-messages="validateErrors.name"
              @keydown.enter.prevent="$refs.surname.focus()"
            />
            <o-text-field
              ref="surname"
              label="Last name"
              v-model="form.surname"
              :error-messages="validateErrors.surname"
              @keydown.enter.prevent="$refs.email.focus()"
            />
          </div>
          <o-text-field
            ref="email"
            label="Work email"
            v-model="form.email"
            :error-messages="validateErrors.email"
            @keydown.enter.prevent="$refs.phone.focus()"
          />
          <o-text-field
            ref="phone"
            label="Phone number"
            v-model="form.phone"
            :error-messages="validateErrors.phone"
            @keydown.enter.prevent="$refs.company.focus()"
          />
          <o-text-field
            ref="company"
            label="Company name"
            v-model="form.company"
            :error-messages="validateErrors.company"
            @keydown.enter.prevent="next()"
            class="mb-3"
          />
          <recaptcha
            ref="recaptcha"
            v-model="form.recaptcha_token"
            @verify="next"
          />
          <base-button
            class="mt-5 next-button"
            @click="next()"
            :processing="processing"
            >Book your visit</base-button
          >
          <p class="terms">
            By continuing, you accept the
            <a href="/terms" target="_blank">Terms &amp; Conditions</a> and
            <a href="/privacy" target="_blank">Privacy Policy</a
            ><template v-if="hasTerms"
              >, as well as
              <a @click="openTerms = true"
                >{{ organization.name }} Terms</a
              ></template
            >.
          </p>
        </div>
      </div>
      <text-Modal
        v-model="openTerms"
        :pre="true"
        :text="organization.custom_terms"
        title="Terms of Service"
      />
    </o-layout>
  </layout-content>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import OptixV2Service from "../services/OptixV2Service";
import Recaptcha from "@/components/Recaptcha";
import Validate from "optixapp-ui-kit/src/vue/mixins/Validate";
import _get from "lodash/get";

export default {
  components: { Recaptcha },
  mixins: [Validate],
  data() {
    return {
      openTerms: false,
      processing: false,
      form: {
        name: "",
        surname: "",
        email: "",
        phone: "",
        company: "",
        recaptcha_token: "",
      },
    };
  },
  created() {
    if (!this.tourContext.location) {
      this.$router.replace({ name: "tour" });
      return;
    }
    this.selectLocation(this.tourContext.location);
  },
  computed: {
    ...mapState(["tourContext", "organization", "mode"]),
    sideBySide() {
      return this.$vuetify.breakpoint.width >= 950;
    },
    hasTerms() {
      return !!this.organization.custom_terms;
    },
  },
  methods: {
    ...mapMutations(["appendSnackbarError", "selectLocation"]),
    next() {
      Validate.utils.trimFields(this.form);
      this.form.phone = this.form.phone.replace(/[^0-9+]+/g, "");

      this.validateForm(this.form, {
        name: [Validate.rules.required],
        surname: [Validate.rules.required],
        email: [Validate.rules.email],
        phone: [Validate.rules.phone],
        company: [Validate.rules.required],
      })
        .then(() => {
          if (!this.form.recaptcha_token) {
            this.$refs.recaptcha.execute();
            return;
          }

          this.processing = true;
          OptixV2Service.createTour({
            ...this.form,
            location_id: this.tourContext.location.location_id,
            organization_id: this.organization.organization_id,
            start_timestamp: this.tourContext.start_timestamp,
            end_timestamp: this.tourContext.end_timestamp,
          })
            .then((tour) => {
              let error = _get(tour, "data.errors.0.message", false);
              let errorCategory = _get(
                tour,
                "data.errors.0.extensions.category",
                false
              );
              let web_link = _get(tour, "data.data.tourCreate.web_link", false);
              if (error) {
                this.appendSnackbarError({ message: error });
                this.processing = false;
              } else if (web_link) {
                // Get JWT token and redirect to the confirmation page
                let confirmation = web_link
                  .replace(/^.+\/confirmation\//, "")
                  .split("/");
                this.appendSnackbarError({
                  type: "success",
                  message: "Tour booking confirmed!",
                });
                this.$gtag.event("tour_confirmed", {
                  event_category: "Tour",
                  event_label: "Confirmed",
                  value: confirmation[0],
                });
                this.$router.replace({
                  name: "tour-confirmation",
                  params: {
                    jwt_token: confirmation[1],
                    tour_id: confirmation[0],
                  },
                });
              } else if (errorCategory == "tour") {
                this.appendSnackbarError({
                  message: error,
                });
                this.processing = false;
              } else {
                this.appendSnackbarError({
                  message: "An error occurred, please try again later.",
                });
                this.processing = false;
              }
            })
            .catch(() => {
              this.appendSnackbarError({
                message: "An error occurred, please try again later.",
              });
              this.processing = false;
              this.$refs.recaptcha.reset();
            });
        })
        .catch(() => {
          this.processing = false;
        });
    },
  },
};
</script>
<style scoped>
.left-side {
  padding-top: 64px;
  flex-basis: 40%;
  border-right: 1px solid rgba(var(--optix-theme-oppositeBorderRGBColor), 0.06);
}
.right-side {
  padding-top: 64px;
  margin-right: 32px;
  flex-basis: 60%;
}
.right-side.no-margin {
  margin-right: 0;
}
.right-side.fullsize {
  flex-basis: 100%;
}
h2 {
  font-size: 20.2px;
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 23px;
}
.form .line {
  display: flex;
  flex-direction: row;
}
.form .line > *:first-child {
  margin-right: 24px;
}
/* Make fields not side by side */
@media (max-width: 500px) {
  .form .line {
    flex-direction: column;
  }
  .form .line > *:first-child {
    margin-right: 0;
  }
}
.form .base-button {
  margin-top: 0 !important;
}
.terms {
  margin-top: 24px;
  font-size: 12px;
  line-height: 17px;
  font-weight: 400;
}
</style>
