<template>
  <div class="days">
    <div class="left" @click="lessDays">
      <v-icon class="default-font-color">mdi-chevron-left</v-icon>
    </div>
    <div
      class="day"
      :class="{
        selected: day.selected,
        disabled: day.disabled,
        enabled: !day.disabled,
        highlighted: day.highlighted,
      }"
      v-for="day in days"
      :key="day.dayDateTime.toSeconds()"
      @click="selectDay(day)"
    >
      <div class="weekday">{{ day.weekday }}</div>
      <div class="day">{{ day.day }}</div>
    </div>
    <div class="right" @click="moreDays">
      <v-icon class="default-font-color">mdi-chevron-right</v-icon>
    </div>
  </div>
</template>
<script>
import { DateTime } from "luxon";

export default {
  props: {
    maxLength: {
      default: 7,
    },
    value: {
      default: null,
    },
    timezone: {
      default: DateTime.local().zone.name,
    },
    min: {
      default: null,
    },
  },
  data() {
    return {
      currentDateTime: null,
      currentStartingDate: null,
    };
  },
  created() {
    let now = DateTime.local();
    this.currentDateTime = DateTime.local(now.year, now.month, now.day);
    this.currentStartingDate = this.currentDateTime;
  },
  mounted() {
    this.alignDays();
  },
  computed: {
    days() {
      let days = [];
      let start_timestamp = parseInt(this.currentStartingDate.toSeconds());
      let selected = this.selectedDateTime.toISO().substr(0, 10);
      for (let day = 0; day < this.maxLength; day++) {
        let dayDateTime = DateTime.fromSeconds(start_timestamp, {
          zone: this.timezone,
        }).plus({ day: day + 1 });
        let isoDateTime = dayDateTime.toISO().substr(0, 10);
        days.push({
          dayDateTime,
          timestamp: dayDateTime.toSeconds(),
          weekday: dayDateTime.weekdayShort,
          day: dayDateTime.day,
          selected: isoDateTime == selected,
          highlighted: false,
          disabled: this.min && isoDateTime < this.min,
        });
      }
      return days;
    },
    selectedDateTime() {
      if (this.value) {
        let datetime = DateTime.fromISO(this.value + "T00:00:00", {
          zone: this.timezone,
        });
        return datetime;
      }
      return this.currentDateTime;
    },
  },
  watch: {
    value(newValue) {
      if (newValue) {
        this.alignDays();
      }
    },
  },
  methods: {
    alignDays() {
      let start_timestamp = parseInt(this.currentStartingDate.toSeconds());
      let end_timestamp = start_timestamp + 24 * 60 * 60 * this.maxLength;
      // If calendar length > 7 days always align the days to the current week
      if (this.maxLength == 7) {
        let weekday = this.selectedDateTime.weekday;
        this.currentStartingDate = this.selectedDateTime.plus({
          day: (weekday == 7 ? 0 : -weekday) - 1,
        });
      } else if (
        this.selectedDateTime.toSeconds() < start_timestamp ||
        this.selectedDateTime.toSeconds() > end_timestamp
      ) {
        this.currentStartingDate = this.selectedDateTime;
      }
    },
    lessDays() {
      let dayDateTime = this.selectedDateTime.plus({
        days: -this.maxLength,
      });
      if (dayDateTime.toSeconds() < this.currentDateTime.toSeconds()) {
        dayDateTime = this.currentDateTime;
      } else {
        this.currentStartingDate = this.currentStartingDate.plus({
          days: -this.maxLength,
        });
      }
      this.selectDay({
        dayDateTime,
      });
    },
    moreDays() {
      this.selectDay({
        dayDateTime: this.selectedDateTime.plus({
          days: this.maxLength,
        }),
      });
      this.currentStartingDate = this.currentStartingDate.plus({
        days: this.maxLength,
      });
    },
    selectDay(day) {
      if (!day.disabled) {
        this.$emit("input", day.dayDateTime.toISO().substr(0, 10));
      }
    },
  },
};
</script>
<style scoped>
.days {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  min-height: 54px;
}
.days .left,
.days .right {
  flex-shrink: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
}
.days .day {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  text-align: center;
  font-size: 12.12px;
  font-weight: 500;
  letter-spacing: 0.4px;
  line-height: 16px;
  text-align: center;
  font-weight: 600;
}
.days .day .day {
  border-radius: 50%;
  font-size: 16.16px;
  font-weight: 500;
  letter-spacing: 0.15px;
  line-height: 24px;
  text-align: center;
  display: flex;
  height: 36px;
  width: 36px;
  cursor: pointer;
}
.days .day.selected .day,
.days .day.enabled.selected .day,
.days .day.enabled.selected:hover .day {
  background: var(--optix-theme-accentColor) !important;
  color: rgba(var(--optix-theme-accentTextRGBColor), 1) !important;
}
.days .day.highlighted .day {
  background: rgba(var(--optix-theme-accentRGBColor), 0.1);
  color: rgba(var(--optix-theme-accentRGBColor), 0.8);
}
.days .day.enabled.highlighted:hover .day {
  background: rgba(var(--optix-theme-accentRGBColor), 0.06);
}
.days .day.enabled:hover .day {
  background: rgba(var(--optix-theme-oppositeBorderRGBColor), 0.02);
}
.days .day.disabled {
  opacity: 0.38;
}
.day.selected .weekday {
  color: rgb(var(--optix-theme-accentRGBColor));
}
.weekday {
  padding-bottom: 2px;
  font-weight: 500;
  color: rgba(var(--optix-theme-defaultFontRGBColor), 0.54);
  text-transform: uppercase;
}
</style>
