<template>
  <div
    class="base-button"
    @click="click($event)"
    v-ripple="hasRipple"
    :class="{ outlined, placeholder, monocrome }"
  >
    <slot v-if="!processing"></slot>
    <span v-if="processing" class="loader">
      <img :src="loader" />
    </span>
  </div>
</template>
<script>
import loader from "../assets/loader.svg";
export default {
  props: {
    outlined: {
      default: false,
    },
    placeholder: {
      default: false,
    },
    ripple: {
      default: true,
    },
    monocrome: {
      default: false,
    },
    processing: {
      default: false,
    },
  },
  data() {
    return {
      loader,
    };
  },
  computed: {
    hasRipple() {
      return !this.processing && this.ripple && !this.placeholder;
    },
  },
  methods: {
    click(event) {
      if (!this.processing) {
        this.$emit("click", event);
      }
    },
  },
};
</script>
<style scoped>
.base-button {
  display: inline-block;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: var(--optix-theme-accentColor);
  border-radius: 4px;
  color: rgb(var(--optix-theme-accentTextRGBColor));
  font-weight: 500;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 14px;
}
.base-button:hover {
  background: rgba(var(--optix-theme-accentRGBColor), 0.9);
}

.base-button.outlined {
  background: transparent;
  color: var(--optix-theme-accentColor);
  border: 2px solid var(--optix-theme-accentColor);
}
.base-button.outlined:hover {
  background: rgba(var(--optix-theme-accentRGBColor), 0.09);
}
.base-button.placeholder {
  background: rgba(var(--optix-theme-oppositeBorderRGBColor), 0.06);
  color: transparent;
  cursor: inherit;
}
.base-button.outlined.monocrome {
  background: transparent;
  color: rgba(var(--optix-theme-defaultFontRGBColor), 0.87);
  border: 2px solid rgba(var(--optix-theme-oppositeBorderRGBColor), 0.24);
}
.base-button.outlined.monocrome:hover {
  background: rgba(var(--optix-theme-oppositeBorderRGBColor), 0.03);
}
.base-button .loader img {
  filter: brightness(0) invert(1);
}
</style>
