import AmexDark from "./amex-dark.svg";
import Amex from "./amex.svg";
import BankDark from "./bank-dark.svg";
import Bank from "./bank.svg";
import DiscoverDark from "./discover-dark.svg";
import Discover from "./discover.svg";
import JcbDark from "./jcb-dark.svg";
import Jcb from "./jcb.svg";
import MastercardDark from "./mastercard-dark.svg";
import Mastercard from "./mastercard.svg";
import VisaDark from "./visa-dark.svg";
import Visa from "./visa.svg";
export default {
  AmexDark,
  Amex,
  BankDark,
  Bank,
  DiscoverDark,
  Discover,
  JcbDark,
  Jcb,
  MastercardDark,
  Mastercard,
  VisaDark,
  Visa,
};
