<template>
  <div
    class="top-bar-wrapper"
    :class="{ 'fullscreen-mode': mode == 'fullscreen' }"
    v-if="showTopBar"
  >
    <div
      class="top-bar"
      :class="{
        'fullscreen-mode': mode == 'fullscreen',
        'has-border': hasBorder,
        'is-fixed': isFixed,
      }"
    >
      <div v-if="!showMobileBar" class="layout-content">
        <div class="logo">
          <img
            v-if="logo"
            :src="logo"
            :srcset="logoSrcSet"
            class="clickable"
            @click="$emit('backToStart')"
          />
        </div>
        <div class="contact">
          <base-button
            v-if="contactUsEmail"
            @click="openContact()"
            :outlined="true"
            :monocrome="true"
            >Contact us</base-button
          >
        </div>
      </div>
      <div v-if="showMobileBar" class="layout-content">
        <o-btn
          icon
          class="padded-button default-font-color"
          @click="backActionClick"
          v-if="showCloseButton"
        >
          <v-icon>mdi-close</v-icon>
        </o-btn>
        <o-btn
          icon
          class="padded-button default-font-color"
          @click="backActionClick"
          v-if="showBackButton"
        >
          <v-icon>mdi-arrow-left</v-icon>
        </o-btn>
        <div v-if="showSummary" class="summary" @click="$emit('summaryClick')">
          <v-img
            :src="
              $optix.utils.resizedImage(
                selectedBooking.resource.images[0].url,
                36,
                36
              )
            "
            height="36"
            width="36"
          />
          <div class="text clickable">
            Show summary
            <v-icon class="default-font-color">mdi-menu-down</v-icon>
          </div>
          <div class="price">
            {{ $optix.utils.currencyFormat(selectedBooking.total) }}
          </div>
        </div>
        <div v-else class="page-title">{{ pageTitle }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
export default {
  props: {
    backAction: {
      type: String,
      default: "back",
    },
  },
  methods: {
    backActionClick() {
      this.$emit("backAction");
    },
    openContact() {
      window.open("mailto:" + this.contactUsEmail);
    },
  },
  computed: {
    ...mapState(["mode", "organization"]),
    ...mapGetters(["selectedBooking", "contactUsEmail"]),
    hasBorder() {
      return this.mode == "fullscreen";
    },
    showBackButton() {
      return this.backAction == "back" && this.mode != "fullscreen";
    },
    showCloseButton() {
      return this.backAction == "close" && this.mode == "modal";
    },
    isFixed() {
      return this.mode == "embed" || this.mode == "modal";
    },
    logo() {
      if (!this.organization.logo) {
        return null;
      }

      return (
        "https://www.sharedesk.net/api/images/?url=" +
        this.organization.logo +
        "&h=48"
      );
    },
    logoSrcSet() {
      return [
        "https://www.sharedesk.net/api/images/?url=" +
          this.organization.logo +
          "&h=48&dpr=2 2x",
        "https://www.sharedesk.net/api/images/?url=" +
          this.organization.logo +
          "&h=48&dpr=3 3x",
        "https://www.sharedesk.net/api/images/?url=" +
          this.organization.logo +
          "&h=48&dpr=4 4x",
      ].join(", ");
    },
    showTopBar() {
      if (
        (this.isFixed &&
          !this.showBackButton &&
          !this.showCloseButton &&
          !this.showSummary) ||
        (this.mode == "embed" && this.$route.name == "confirmation")
      ) {
        return false;
      }
      return true;
    },
    hasResourceSelected() {
      return (
        this.selectedBooking.resource &&
        this.selectedBooking.resource.resource_id
      );
    },
    showMobileBar() {
      if (this.mode != "fullscreen") {
        return true;
      }
      if (
        !this.$vuetify.breakpoint.mdAndUp &&
        this.hasResourceSelected &&
        this.showSummary
      ) {
        return true;
      }
      return false;
    },
    showSummary() {
      switch (this.$route.name) {
        case "auth":
        case "complete":
        case "password":
        case "passwordRecovery":
        case "payment":
          return !this.$vuetify.breakpoint.mdAndUp && this.hasResourceSelected;
        default:
          return false;
      }
    },
    pageTitle() {
      switch (this.$route.name) {
        case "find":
          if (this.mode == "fullscreen" || this.mode == "embed") {
            return "Find a room";
          }
          return "";
        default:
          return "";
      }
    },
  },
};
</script>
<style scoped>
.top-bar-wrapper {
  height: 56px;
  width: 100%;
  position: relative;
}
.top-bar-wrapper.fullscreen-mode {
  height: 80px !important;
}
.top-bar {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: stretch;
  height: 56px;
  width: 100%;
  z-index: 100;
  background: var(--optix-theme-navBarColor);
}
.top-bar.fullscreen-mode {
  height: 80px !important;
}
.top-bar.is-fixed {
  position: fixed;
}
.top-bar.has-border {
  border-bottom: 1px solid rgba(var(--optix-theme-oppositeBorderRGBColor), 0.24);
}
.top-bar > * {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 1200px;
  width: 100%;
  height: 100%;
  align-items: center;
}
.top-bar .logo {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.top-bar .logo img {
  height: 48px;
  border-radius: 12px;
}
.top-bar .contact > div {
  padding-left: 16px;
  padding-right: 16px;
  font-size: 14px;
}
.top-bar .v-btn.v-btn--icon {
  color: rgba(var(--optix-theme-oppositeBorderRGBColor), 1);
}
.summary {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
}
@media (max-width: 550px) {
  .summary {
    margin-left: -12px;
  }
  .top-bar.fullscreen-mode .summary {
    margin-left: 0px;
  }
}
.summary .v-image {
  border-radius: 50%;
  max-width: 36px;
  margin-right: 8px;
}
.summary > .text {
  font-size: 16px;
  letter-spacing: 0.2px;
  line-height: 28px;
  flex-grow: 2;
}
.summary > .price {
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.25px;
  line-height: 22px;
}
.page-title {
  width: 100%;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 24px;
}
.padded-button {
  margin-right: 24px;
}
</style>
