<template>
  <o-flex class="tour-link">
    <tour-icon />
    <strong>Rather visit us first?</strong>&nbsp;
    <span class="happy">We'd be happy to show you around.</span>&nbsp;
    <a @click="goToTour">Book a tour</a>&nbsp;
    <a @click="goToTour" class="no-underline">
      <o-icon icon="o-icon-arrow-right-feather" />
    </a>
  </o-flex>
</template>
<script>
import TourIcon from "../assets/icons/tour";
export default {
  components: {
    TourIcon,
  },
  methods: {
    goToTour() {
      this.$router.push({ name: "tour" });
    },
  },
};
</script>
<style scoped>
.tour-link {
  font-size: 16px;
  letter-spacing: 0;
  line-height: 18px;
  margin-bottom: 32px;
}
strong {
  font-weight: 500;
}
@media (max-width: 600px) {
  .happy {
    display: none;
  }
}
.tour-link .tour-icon {
  margin-bottom: -8px;
  margin-right: 8px;
}
.tour-link .o-icon {
  font-size: 24px;
  margin-top: -4px;
}
.tour-link a {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 18px;
}
</style>
