export default {
  // General things ////////////////////////////////////////////////////////////////////////////////////////////////////
  // Snackbar
  generalSnackbar: {
    open: false,
    messageCounter: 0,
    closeTimeout: null,
    messages: [],
    type: "error",
  },
  // Window mode, based on app.vue detection (fullscreen, modal or embed)
  mode: null,
  // Base subdomain detected at the app.vue
  subdomain: null,
  // Organization info loaded after init, based on the subdomain provided
  organization: {},
  // All locations with at least one resource
  availableLocations: [],
  // Drop-in booking related ///////////////////////////////////////////////////////////////////////////////////////////
  // Resource selected by Find or Resource page
  resourceSelected: {},
  // Booking time selected (timestamps)
  bookingStartTimestamp: null,
  bookingDuration: null,
  bookingEndTimestamp: null,
  // Location selected based on the resource selected at Find or Resource page
  locationSelected: {},
  // Filters for Find
  filterLocations: [],
  filterMinimumCapacity: null,
  filterResourceType: null,
  // Filters for Resource and Find page
  filterInitialDate: null,
  filterInitialTime: null,
  filterInitialDuration: null,
  // User data
  user: {},
  // Lookup context
  lookup: {},
  // Drafted booking, generated at payment interface
  draftedBooking: {},
  // Committed booking
  committedBooking: {},
  // Tour related //////////////////////////////////////////////////////////////////////////////////////////////////////
  tourContext: {
    state: "none", // none/creating/committed
    location: false,
    start_timestamp: false,
    end_timestamp: false,
    user: {},
  },
};
