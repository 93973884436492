// Base Vue stuff
import Vue from "vue";
Vue.config.productionTip = false;

// Optix UI kit
import OptixUIPlugin from "optixapp-ui-kit/src/vue-plugin";
import "optixapp-ui-kit/src/vue-base.css";
import "optixapp-ui-kit/src/icons";
Vue.use(OptixUIPlugin, {
  themed: true, // Create stylesheets and support theming functions
});

// Vuetify components
import Vuetify from "vuetify/lib";
Vue.use(Vuetify);

// Custom components, autoloaded to make things less messy
// Import all Optix UI components
import upperFirst from "lodash/upperFirst";
import camelCase from "lodash/camelCase";
const requireComponent = require.context(
  "./components", // The relative path of the components folder
  true, // Whether or not to look in subfolders
  /.*\.(vue|js)$/
);
requireComponent.keys().forEach((fileName) => {
  const componentConfig = requireComponent(fileName);
  const componentName = upperFirst(
    camelCase(fileName.replace(/^\.\/(.*)\.\w+$/, "$1"))
  );
  Vue.component(componentName, componentConfig.default || componentConfig);
});

// Routes
import VueRouter from "vue-router";
Vue.use(VueRouter);

import Find from "./steps/Find.vue";
import Resource from "./steps/Resource.vue";
import Auth from "./steps/Auth.vue";
import Complete from "./steps/Complete.vue";
import Payment from "./steps/Payment.vue";
import PasswordRecovery from "./steps/PasswordRecovery.vue";
import Password from "./steps/Password.vue";
import Confirmation from "./steps/Confirmation.vue";
import TourDateSelect from "./steps/TourDateSelect.vue";
import TourForm from "./steps/TourForm.vue";
import TourConfirmation from "./steps/TourConfirmation.vue";

const routes = [
  { path: "/", component: Find, name: "find" },
  { path: "/tour", component: TourDateSelect, name: "tour" },
  { path: "/tour/request", component: TourForm, name: "tour-request" },
  {
    path: "/tour/confirmation/:tour_id/:jwt_token",
    component: TourConfirmation,
    name: "tour-confirmation",
  },
  {
    path: "/resource/:resource_id/pick",
    component: Resource,
    name: "resource-picker",
    meta: { picker: true },
  },
  {
    path: "/resource/:resource_id",
    component: Resource,
    name: "resource",
    meta: { picker: false },
  },
  { path: "/auth", component: Auth, name: "auth" },
  { path: "/complete", component: Complete, name: "complete" },
  { path: "/payment", component: Payment, name: "payment" },
  {
    path: "/password-recovery",
    component: PasswordRecovery,
    name: "passwordRecovery",
  },
  { path: "/password", component: Password, name: "password" },
  {
    path: "/confirmation/:booking_id/:jwt_token",
    component: Confirmation,
    name: "confirmation",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// Vuex
import Vuex from "vuex";
import state from "./vuex/state";
import mutations from "./vuex/mutations";
import getters from "./vuex/getters";
Vue.use(Vuex);
const store = new Vuex.Store({
  state,
  mutations,
  getters,
});

// Clipboard plugin
import VueClipboard from "vue-clipboard2";
VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);

// Analytics
import VueGtag from "vue-gtag";
Vue.use(
  VueGtag,
  {
    config: {}, // Config at the app.vue
    bootstrap: false,
  },
  router
);

// This app is loaded before the dom is available
import App from "./App.vue";
import "./base.css";
import "./base.sass";
document.addEventListener("DOMContentLoaded", function () {
  new Vue({
    vuetify: new Vuetify({
      breakpoint: {
        thresholds: {
          //xs: 340,
          sm: 800,
          //md: 600,
          //lg: 1280,
        },
        //scrollBarWidth: 24,
      },
    }),
    router,
    store,
    render: (h) => h(App),
  }).$mount("#app");
});
