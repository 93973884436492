<template>
  <svg width="720" height="194" viewBox="0 0 720 194" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title>Software for better coworking spaces</title>
    <path d="M290.408 146.295C250.118 146.295 220 114.293 220 74.7377C220 35.1824 250.118 3.18032 290.408 3.18032C330.898 3.18032 360.816 35.1824 360.816 74.7377C360.816 114.293 330.898 146.295 290.408 146.295ZM290.408 121.846C315.938 121.846 335.485 100.975 335.485 74.7377C335.485 48.5 315.938 27.6291 290.408 27.6291C264.878 27.6291 245.53 48.5 245.53 74.7377C245.53 100.975 264.878 121.846 290.408 121.846Z" :fill="color"/>
    <path d="M401.009 194H377V43H401.009V56.8484C409.187 47.7049 421.353 41.9406 434.916 41.9406C464.635 41.9406 484.581 64.7992 484.581 93.6209C484.581 122.443 464.635 145.301 434.916 145.301C421.353 145.301 409.187 139.537 401.009 130.393V194ZM430.927 124.629C448.28 124.629 460.846 111.311 460.846 93.6209C460.846 75.9303 448.28 62.6127 430.927 62.6127C413.774 62.6127 401.009 75.9303 401.009 93.6209C401.009 111.311 413.774 124.629 430.927 124.629Z" :fill="color"/>
    <path d="M538 144H514V65H494V43H514V10H538V43H560.963L561 65H538V144Z" :fill="color"/>
    <path d="M588.108 28.2254C580.529 28.2254 573.947 21.8647 573.947 13.7152C573.947 6.36066 580.529 0 588.108 0C595.687 0 602.269 6.36066 602.269 13.7152C602.269 21.8647 595.887 28.2254 588.108 28.2254ZM600 144H576V43H600V144Z" :fill="color"/>
    <path d="M642.411 144H613.69L649.991 92.0307L615.684 43H644.805L666.945 76.1291L689.084 43H718.005L683.699 92.0307L720 144H691.677L666.945 108.131L642.411 144Z" :fill="color"/>
    <path d="M80 3H160V83H80V3Z" :fill="color"/>
    <rect y="83" width="80" height="80" :fill="color"/>
  </svg>

</template>
<script>
export default {
  computed: {
    color() {
      return this.$optix.theme.darkMode ? "#ffffff" : "#141414";
    },
  },
};
</script>
