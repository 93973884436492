<template>
  <v-dialog v-model="isOpen" persistent max-width="600px">
    <v-card>
      <v-card-title>
        <span class="headline">Share link</span>
      </v-card-title>
      <v-card-text class="base-container share-link">
        <v-container>
          <v-row>
            <o-text-field
              @click="selectAll"
              label="Share link"
              :readonly="true"
              v-model="link"
            ></o-text-field>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <o-btn class="text-button" text @click="copyContent">Copy</o-btn>
        <v-spacer />
        <o-btn class="text-button" text @click="isOpen = false">Close</o-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    value: {
      default: false,
    },
  },
  data() {
    return {};
  },
  watch: {
    value() {
      this.selectAll();
    },
  },
  computed: {
    link: {
      get() {
        return window.location.href;
      },
      set() {
        // ignore
      },
    },
    isOpen: {
      get() {
        return this.value;
      },
      set(open) {
        this.$emit("input", open);
      },
    },
  },
  methods: {
    selectAll() {
      setTimeout(() => {
        try {
          document.querySelector(".base-container.share-link input").select();
          // eslint-disable-next-line no-empty
        } catch (_) {}
      }, 100);
    },
    copyContent() {
      this.selectAll();
      this.$copyText(this.link);
    },
  },
};
</script>
<style scoped>
.base-container {
  margin-bottom: -48px;
}
</style>
