// Map get and setters for computed properties be used at v-model variables
export const mapSetter = (state, setters = {}) =>
  Object.keys(state).reduce((acc, stateName) => {
    acc[stateName] = {
      get: state[stateName],
    };
    if (setters[stateName]) {
      acc[stateName].set = setters[stateName];
    }
    return acc;
  }, {});
