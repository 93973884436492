<template>
  <span>{{ timeLength }} {{ unitDescription }}</span>
</template>
<script>
export default {
  props: {
    duration: {
      default: 0,
    },
    unit: {
      default: "hour",
    },
  },
  computed: {
    timeLength() {
      if (this.unit == "hour") {
        return Math.round((this.duration / 60 / 60) * 100) / 100;
      }
      return Math.ceil(this.duration / 60 / 60 / 24);
    },
    unitDescription() {
      return this.timeLength > 1 ? this.unit + "s" : this.unit;
    },
  },
};
</script>
