<template>
  <layout-content>
    <o-layout row>
      <o-flex class="left-side">
        <p class="mb-5 mt-2">
          We've emailed a password reset code to {{ user.email }}. Grab that
          code and enter it here to set a new password.
        </p>

        <o-text-field
          label="Reset code"
          v-model="form.code"
          :error-messages="validateErrors.code"
        />
        <o-text-field
          type="password"
          label="Password"
          v-model="form.password"
          :error-messages="validateErrors.password"
        />
        <base-button
          class="mt-5 next-button"
          @click="next()"
          :processing="processing"
          >Next</base-button
        >
      </o-flex>
      <o-flex v-if="$vuetify.breakpoint.mdAndUp" class="right-side">
        <booking-side-card :booking="selectedBooking" />
      </o-flex>
    </o-layout>
  </layout-content>
</template>
<script>
import { mapState, mapGetters, mapMutations } from "vuex";
import OptixV1Service from "../services/OptixV1Service";
import Validate from "optixapp-ui-kit/src/vue/mixins/Validate";

export default {
  mixins: [Validate],
  data() {
    return {
      processing: false,
      form: {
        password: "",
        code: "",
      },
    };
  },
  computed: {
    ...mapState(["user", "lookup"]),
    ...mapGetters(["selectedBooking"]),
  },
  mounted() {
    if (!this.selectedBooking.resource.resource_id) {
      this.$router.push({ name: "find" });
      return;
    }
    if (!this.lookup.member_account || !this.user.email) {
      this.$router.push({ name: "auth" });
      return;
    }
    OptixV1Service.sendRecoveryEmail({ email: this.user.email })
      .then(() => {})
      .catch(() => {});
  },
  methods: {
    ...mapMutations(["setUser"]),
    next() {
      this.validateForm(this.form, {
        code: [Validate.rules.resetCode],
        password: [Validate.rules.password],
      })
        .then(() => {
          this.processing = true;
          OptixV1Service.recoverPassword({
            ...this.form,
            email: this.user.email,
          })
            .then((success) => {
              if (
                typeof success.data.response === "string" &&
                success.data.response != "Password successfully reset"
              ) {
                this.addFormFieldError("password", success.data.response);
              } else {
                // Tries to authenticate
                OptixV1Service.login({
                  ...this.form,
                  email: this.user.email,
                }).then((success) => {
                  if (typeof success.data.response === "string") {
                    this.addFormFieldError("password", success.data.response);
                  } else {
                    this.setUser(success.data.response.user_data);
                    this.$optix.env.access_token =
                      success.data.response.access_token;
                    this.$router.push({ name: "payment" });
                  }
                  this.processing = false;
                });
              }
              this.processing = false;
            })
            .catch(() => {
              this.addFormFieldError(
                "password",
                "An error happened recovering your password"
              );
            });
        })
        .catch(() => {});
    },
  },
};
</script>
<style scoped>
.left-side p {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.2px;
  line-height: 28px;
}
</style>
