<template>
  <div class="powered-by-spacer">
    <div class="powered-by-wrap">
      <div class="powered-by">
        <a href="https://www.optixapp.com" target="_blank">
          <span>Powered by</span>
          <optix-logo class="optix-logo" />
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import OptixLogo from "../assets/OptixLogo";
export default {
  components: {
    OptixLogo,
  },
};
</script>
<style scoped>
.powered-by-spacer {
  width: 100%;
  height: 48px;
}
.powered-by-wrap {
  position: fixed;
  bottom: 0px;
  width: 100%;
  height: 48px;
  background: rgba(var(--optix-theme-backgroundRGBColor), 1);
  z-index: 1;
  color: var(--optix-theme-defaultFontColor);
}
.powered-by {
  height: 100%;
}
.powered-by a {
  height: 100%;
  align-items: center;
  background: rgba(var(--optix-theme-oppositeBorderRGBColor), 0.03);
  display: flex;
  justify-content: center;
  color: rgba(var(--optix-theme-defaultFontRGBColor), 0.87);
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
}
.powered-by a:hover {
  text-decoration: none;
}
.optix-logo {
  height: 24px;
  max-width: 110px;
}
</style>
