<template>
  <div class="card clickable">
    <v-layout class="row">
      <o-radio :value="pm.name" ref="rad" :disabled="disabled" />
      <div class="name-card" @click="$emit('select', pm.name)">
        New {{ pm.name }}
      </div>
    </v-layout>
    <v-layout column class="canvas" v-if="selected == pm.name">
      <!-- <div v-if="addedPaymentMethod">
        <div class="added-block">
          <o-icon icon="mdi-check-circle" /> Bank account successfully added
        </div>
        <a @click="cleanup()">Switch payment method</a>
      </div> -->
      <div v-if="waiting" style="text-align: center">
        <v-progress-circular
          indeterminate
          class="mb-3 mt-2"
        ></v-progress-circular
        ><br />
        Waiting for completion in a new tab.
        <a :href="pm.url" target="_blank">Re-open tab</a>
      </div>
      <div v-else>
        Complete this process securely in a new tab<br />
        <o-btn
          @click="waitForPaymentMethod(pm.url)"
          style="margin-top: 8px; position: relative"
          >Connect Account</o-btn
        >
      </div>
    </v-layout>
  </div>
</template>
<script>
import OptixV1Service from "../services/OptixV1Service";

export default {
  mixins: [],
  name: "retainable-payment-method",
  props: {
    pm: {
      default: null,
    },
    member_account: {
      default: null,
    },
    organization_id: {
      default: null,
    },
    selected: {
      default: null,
    },
    disabled: {
      default: false,
    },
  },
  data() {
    return {
      waiting: false,
      intervalWatcher: null,
      initialPaymentMethodsCount: -1,
      currentPaymentMethods: [],
      addedPaymentMethod: null,
    };
  },
  unmounted() {
    this.stopWatching();
  },
  methods: {
    cleanup() {
      this.addedPaymentMethod = null;
      this.$emit("input", null);
    },
    stopWatching() {
      if (this.intervalWatcher) {
        clearInterval(this.intervalWatcher);
      }
    },
    async checkPaymentMethod() {
      let pm_response = await OptixV1Service.getPaymentMethods(
        this.member_account
      );
      let pm = pm_response.data.response.payment_methods;
      if (this.initialPaymentMethodsCount == -1) {
        this.initialPaymentMethodsCount = pm.length;
        this.currentPaymentMethods = pm.map((x) => x.pm_id);
      } else if (this.initialPaymentMethodsCount != pm.length) {
        this.addedPaymentMethod = pm.filter(
          (x) => !this.currentPaymentMethods.includes(x.pm_id)
        )[0];
        this.waiting = false;
        this.stopWatching();
        this.$emit("added", this.addedPaymentMethod);
      }
    },
    waitForPaymentMethod(url) {
      this.waiting = true;
      this.stopWatching();
      this.intervalWatcher = setInterval(this.checkPaymentMethod, 5000);
      window.open(url, "_blank");
    },
  },
};
</script>
<style lang="css" scoped>
.card {
  border: var(--outline-dark-border);
  border-radius: 4px;
  padding: 24px 24px 24px;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  color: var(--optix-theme-defaultFontColor);
  margin-bottom: 12px;
}
.card .v-radio {
  padding: 0px 8px;
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.name-card {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.canvas {
  width: 100%;
  justify-content: center;
  align-content: flex-start;
  text-align: left;
  padding: 8px 0px 0px;
}
.added-block {
  border-style: solid;
  border-width: 1px;
  width: 330px;
  border-color: var(--outline-dark-border);
  border-radius: 4px;
  padding: 16px;
  margin-bottom: 4px;
  margin-top: 4px;
}
.added-block .v-icon {
  margin-right: 12px;
}
</style>
