import { DateTime } from "luxon";

export default {
  // Handy helper for filters
  filtersUsed(state) {
    return {
      initialDate: state.filterInitialDate,
      locations: state.filterLocations,
      initialTime: state.filterInitialTime,
      initialDuration: state.filterInitialDuration,
      minimumCapacity: state.filterMinimumCapacity,
    };
  },
  selectedStartDateTime(state) {
    if (
      !state.bookingStartTimestamp ||
      !state.bookingDuration ||
      !state.bookingEndTimestamp ||
      !state.locationSelected
    ) {
      return { start: null, end: null, duration: null, timezone: null };
    }
    let start = DateTime.fromSeconds(state.bookingStartTimestamp, {
      zone: state.locationSelected.timezone,
    });
    return {
      start: start,
      end: start.plus({ second: state.bookingDuration }),
      duration: state.bookingDuration,
      timezone: state.locationSelected.timezone,
    };
  },
  selectedBooking(state, getters) {
    if (!state.resourceSelected || !state.locationSelected) {
      return {};
    }
    let period = getters.selectedStartDateTime;
    let hours = period.duration / 60 / 60;
    let amount_per_hour =
      Math.round(state.resourceSelected.price_hour * hours * 100) / 100;
    let days = Math.ceil(hours / 24);
    let amount_per_day =
      Math.round(state.resourceSelected.price_day * days * 100) / 100;
    let best_price =
      amount_per_day == 0 || amount_per_hour < amount_per_day ? "hour" : "day";
    let amount = best_price === "hour" ? amount_per_hour : amount_per_day;
    let is_inclusive_tax = state.organization.features.tax_mode === "INCLUSIVE";
    let tax_rate, tax, total;
    let tax_label = state.organization.features.tax_name || "Tax";
    if (is_inclusive_tax) {
      tax_rate = state.organization.inclusive_tax_rate;
      const tax_base = (amount * 100) / (100 + tax_rate);
      tax = Math.round((amount - tax_base) * 100) / 100;
      total = amount;
    } else {
      tax_rate = state.organization.exclusive_tax_rate;
      tax = Math.round(amount * tax_rate) / 100;
      total = amount + tax;
    }
    let price_per =
      best_price === "hour"
        ? state.resourceSelected.price_hour
        : state.resourceSelected.price_day;
    if (
      state.draftedBooking &&
      state.draftedBooking.bookings &&
      state.draftedBooking.bookings[0]
    ) {
      let drafted = state.draftedBooking.bookings[0];
      is_inclusive_tax = drafted.payment.inclusive_tax_rate > 0;
      tax = is_inclusive_tax
        ? drafted.payment.included_tax
        : drafted.payment.tax;
      total = drafted.payment.total;
      price_per = drafted.payment.unit_amount;
    }
    if (is_inclusive_tax) {
      tax_label = "Includes " + (tax_label === "Tax" ? "tax" : tax_label);
    }
    return {
      period,
      resource: state.resourceSelected,
      location: state.locationSelected,
      total,
      tax_label,
      tax,
      amount,
      price_per,
      unit: best_price,
    };
  },
  paymentGateway(state) {
    if (!state.organization || !state.organization.payment_gateway) {
      return {
        processor: null,
        engine: null,
      };
    }
    let processor = "none";
    if (state.organization.payment_gateway.gateway_id > 0) {
      processor =
        state.organization.payment_gateway.gateway_id >= 2
          ? "spreedly"
          : "stripe";
    }
    return {
      processor,
      engine: state.organization.payment_gateway.payment_engine_version,
    };
  },
  contactUsEmail(state) {
    if (
      state.locationSelected &&
      state.locationSelected.contact &&
      state.locationSelected.contact.email
    ) {
      return state.locationSelected.contact.email;
    }
    if (state.organization.contact.email) {
      return state.organization.contact.email;
    }
    return false;
  },
};
