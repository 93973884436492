<template>
  <div>
    <o-layout row align-center class="nowrap">
      <o-flex class="oneline mr-2">
        <o-text-field
          :disabled="disabled"
          label="First name"
          :error-messages="validateErrors.firstName"
          v-model="firstName"
          @keydown.enter.prevent="$refs.lastName.focus()"
        />
      </o-flex>
      <o-flex class="oneline">
        <o-text-field
          :disabled="disabled"
          label="Last name"
          ref="lastName"
          :error-messages="validateErrors.lastName"
          v-model="lastName"
          @keydown.enter.prevent="$refs.cardNumber.focus()"
        />
      </o-flex>
    </o-layout>
    <!--o-layout row align-center>
      <o-flex class="oneline">
        <o-text-field label="Email" :error-messages="validateErrors.email" v-model="email" />
      </o-flex>
    </o-layout-->
    <o-layout row align-center>
      <o-flex class="oneline">
        <o-text-field
          :disabled="disabled"
          label="Card number"
          type="number"
          pattern="\d*"
          ref="cardNumber"
          :error-messages="validateErrors.cardNumber"
          v-model="cardNumber"
          @keyup="cleanCardNumber"
          @keydown.enter.prevent="$refs.expireMonth.focus()"
        />
      </o-flex>
    </o-layout>
    <o-layout row class="mb-4">
      <o-flex class="date">
        <o-text-field
          :disabled="disabled"
          ref="expireMonth"
          type="number"
          pattern="\d*"
          label="Month"
          hint="Expiry MM"
          persistent-hint
          v-model="expireMonth"
          @keyup="cleanExpireMonth"
          :min="1"
          :max="12"
          class="mr-1"
          :error="
            validateErrors.expireMonth && validateErrors.expireMonth != ''
          "
          @keydown.enter.prevent="$refs.expireYear.focus()"
        />
      </o-flex>
      <o-flex class="date">
        <o-text-field
          :disabled="disabled"
          ref="expireYear"
          type="number"
          pattern="\d*"
          label="Year"
          hint="Expiry YYYY"
          persistent-hint
          v-model="expireYear"
          @keydown.enter.prevent="$refs.cvc.focus()"
          @keyup="cleanExpireYear"
          :min="2019"
          :max="2050"
          :error="validateErrors.expireYear && validateErrors.expireYear != ''"
        />
      </o-flex>
      <o-flex class="cvc">
        <o-text-field
          :disabled="disabled"
          type="number"
          ref="cvc"
          pattern="\d*"
          label="CVC"
          hint="3 digits on the back"
          persistent-hint
          v-model="cvc"
          class="pl-1"
          @keyup="cleanCvc"
          :min="100"
          :max="9999"
          :error="validateErrors.cvc && validateErrors.cvc != ''"
          @keydown.enter.prevent="$refs.zip.focus()"
        />
      </o-flex>
    </o-layout>
    <o-layout row align-center class="mb-4">
      <o-flex class="zip">
        <o-text-field
          :disabled="disabled"
          ref="zip"
          label="ZIP/Postal code"
          hint="The postal or ZIP code from the billing address"
          persistent-hint
          v-model="zip"
          :error="validateErrors.zip && validateErrors.zip != ''"
        />
      </o-flex>
    </o-layout>
  </div>
</template>
<script>
import Validate from "optixapp-ui-kit/src/vue/mixins/Validate";
import OptixV1Service from "../services/OptixV1Service";
import axios from "axios";
import forEach from "lodash/forEach";
import { mapState } from "vuex";

export default {
  mixins: [Validate],
  props: {
    disabled: {
      default: false,
    },
  },
  data() {
    return {
      cardNumber: "",
      expireMonth: "",
      expireYear: "",
      cvc: "",
      zip: "",
      firstName: "",
      lastName: "",
      email: "",
    };
  },
  mounted() {
    this.firstName = this.user.name;
    this.lastName = this.user.surname;
    this.email = this.user.email;
  },
  computed: {
    ...mapState(["user", "organization", "lookup"]),
  },
  methods: {
    cleanCardNumber(event) {
      let clean = this.cardNumber.toString().replace(/[^0-9]+/, "");
      if (clean != this.cardNumber) {
        this.cardNumber = clean;
      }
      if (event.key != "Tab" && event.key != "Shift" && clean.length == 19) {
        this.$refs.expireMonth.focus();
      }
    },
    cleanExpireMonth(event) {
      let clean = this.expireMonth.toString().replace(/[^0-9]+/, "");
      if (clean != this.expireMonth) {
        this.expireMonth = clean;
      }
      if (this.expireMonth > 12) {
        this.expireMonth = 12;
      }
      if (this.expireMonth < 0 && this.expireMonth != "") {
        this.expireMonth = 1;
      }

      if (
        event.key != "Tab" &&
        event.key != "Shift" &&
        (this.expireMonth > 1 || this.expireMonth.length == 2)
      ) {
        this.$refs.expireYear.focus();
      }
    },
    cleanExpireYear(event) {
      let clean = this.expireYear.toString().replace(/[^0-9]+/, "");
      if (clean != this.expireYear) {
        this.expireYear = clean;
      }
      if (this.expireYear > 2050) {
        this.expireYear = 2050;
      }
      if (
        this.expireYear < 2019 &&
        this.expireYear != "" &&
        this.expireYear.length >= 4
      ) {
        this.expireYear = 2019;
      }
      if (
        event.key != "Tab" &&
        event.key != "Shift" &&
        this.expireYear.length == 4
      ) {
        this.$refs.cvc.focus();
      }
    },
    cleanCvc(event) {
      let clean = this.cvc
        .toString()
        .replace(/[^0-9]+/, "")
        .substring(0, 4);
      if (clean != this.cvc) {
        this.cvc = clean;
      }
      if (event.key != "Tab" && event.key != "Shift" && this.cvc.length == 4) {
        if (this.$refs.zip) {
          this.$refs.zip.focus();
        }
      }
    },
    validateInfo() {
      let currentDate = new Date();
      let currentYear = currentDate.getFullYear().toString();
      let checkMonth = (month) => {
        if (
          month == "" ||
          (this.expireYear == currentYear && currentDate.getMonth() > month) ||
          month < 1 ||
          month > 12
        ) {
          return "Invalid month";
        }
        return "";
      };
      let checkYear = (year) => {
        if (parseInt(year) < currentYear || year > 2050 || year == "") {
          return "Invalid year";
        }
        return "";
      };

      // Basic validations
      let validations = {
        firstName: [Validate.rules.required],
        lastName: [Validate.rules.required],
        email: [Validate.rules.email],
        cardNumber: [
          Validate.rules.required,
          Validate.rules.min(14),
          Validate.rules.max(19),
        ],
        expireMonth: [checkMonth],
        expireYear: [checkYear],
        cvc: [(value) => (value > 0 && value < 9999 ? "" : "Invalid field")],
        zip: [Validate.rules.required],
      };

      return this.validateForm(this, validations);
    },
    collectSpreedlyErrors(errors) {
      let generic = [];
      forEach(errors, (error) => {
        switch (error.attribute) {
          case "number":
            this.addFormFieldError("cardNumber", error.message);
            break;
          case "year":
            this.addFormFieldError("expireYear", error.message);
            break;
          case "month":
            this.addFormFieldError("expireMonth", error.message);
            break;
          case "verification_value":
            this.addFormFieldError("cvc", error.message);
            break;
          default:
            generic.push(error.message);
        }
      });
      return generic;
    },
    processCard() {
      this.validateInfo();
      return new Promise((accept, reject) => {
        this.validateInfo()
          .then(() => {
            let data = {
              payment_method: {
                credit_card: {
                  first_name: this.firstName,
                  last_name: this.lastName,
                  email: this.email,
                  number: this.cardNumber,
                  verification_value: this.cvc,
                  month: this.expireMonth,
                  year: parseFloat(this.expireYear),
                  zip: this.zip,
                },
              },
            };
            let spreedlyKey = this.$optix.env.getConf("spreedly_key");
            let url = `https://core.spreedly.com/v1/payment_methods.json?environment_key=${spreedlyKey}`;
            axios
              .post(url, data, {
                headers: {
                  "Content-Type": "application/json",
                },
              })
              .then((response) => {
                if (response.status == 201) {
                  let acceptObject = {
                    venue_id: this.organization.organization_id,
                    processor_id: 2, // spreedly
                    details: response.data.transaction.payment_method,
                    retained: true,
                    type_id: 1, // Card
                  };
                  acceptObject.member_id = this.lookup.member_account;
                  OptixV1Service.addPaymentMethod(acceptObject)
                    .then((success) => {
                      this.$emit("success", success.data.response);
                      this.number = "";
                      this.verification_value = "";
                      this.month = "";
                      this.year = "";
                      this.zip = "";
                      accept(success.data.response);
                    })
                    .catch((error) => {
                      let errors = {
                        errors: error,
                        messages: [
                          "Error adding payment method, please try again.",
                        ],
                      };
                      this.$emit("error", errors);
                      reject(errors);
                    });
                } else {
                  let messages = this.collectSpreedlyErrors(
                    response.data.errors
                  );
                  let errors = {
                    errors: response.data.errors,
                    messages,
                  };
                  if (messages && messages[0]) {
                    this.$optix.canvas.pushSnackbar({
                      type: "error",
                      message: messages[0],
                    });
                  }
                  this.$emit("error", errors);
                  reject(errors);
                }
              })
              .catch((error) => {
                let messages = this.collectSpreedlyErrors(error);
                let errors = {
                  errors: error.response.data.errors,
                  messages: messages,
                };
                this.$emit("error", error.response.data.errors);
                reject(errors);
              });
          })
          .catch((error) => {
            let errors = {
              errors: error,
              messages: ["Error adding payment method, please try again."],
            };
            this.$emit("error", errors);
            reject(errors);
          });
      });
    },
  },
};
</script>
<style scoped>
.cvc {
  flex-basis: 150px;
  max-width: 150px;
}
h3 {
  margin: 24px 0 0 0;
}
</style>
