import queryString from "query-string";
import { DateTime } from "luxon";
import FileSaver from "file-saver";

export default {
  /**
   * Legacy link
   * http://www.google.com/calendar/event?action=TEMPLATE&text=<?= $booking['workspace_type'] ?> at <?= $booking['venue_name'] ?>&dates=<?= Utility::dateToCal($booking['check_in_timestamp']) ?>/<?= Utility::dateToCal($booking['check_out_timestamp']) ?>&details=<?= $booking['workspace_type'] ?> <?= $booking['ws_name'] ?>&location=<?= $location['address'] ?>,<?= $location['city'] ?>,<?= $location['postcode'] ?>&add=<?= $user['email'] ?>&trp=false&sprop=ShareDesk&sprop=name:<?= SITE_URL?>
   */
  openGoogleCal(event) {
    let start = DateTime.fromSeconds(
      event.check_in_timestamp || event.start_timestamp,
      { zone: "UTC" }
    )
      .toFormat("yyyyMMddHHmmss")
      .replace(/(.{8})(.{6})/, "$1T$2Z");
    let end = DateTime.fromSeconds(
      event.check_out_timestamp || event.end_timestamp,
      { zone: "UTC" }
    )
      .toFormat("yyyyMMddHHmmss")
      .replace(/(.{8})(.{6})/, "$1T$2Z");
    let params = {
      action: "TEMPLATE",
      text: `Booking at ${event.location.name}`,
      dates: start + "/" + end,
      details: event.resource ? event.resource.name : event.location.name,
      location:
        (event.location.unit ? `${event.location.unit},` : "") +
        `${event.location.address}, ${event.location.city}`,
      add: event.user.email,
      trp: false,
      sprop: "https://www.optixapp.com",
    };
    window.open(
      "https://www.google.com/calendar/event?" + queryString.stringify(params)
    );
  },
  openICal(event) {
    let start = DateTime.fromSeconds(
      event.check_in_timestamp || event.start_timestamp,
      { zone: "UTC" }
    )
      .toFormat("yyyyMMddHHmmss")
      .replace(/(.{8})(.{6})/, "$1T$2Z");
    let end = DateTime.fromSeconds(
      event.check_out_timestamp || event.start_timestamp,
      { zone: "UTC" }
    )
      .toFormat("yyyyMMddHHmmss")
      .replace(/(.{8})(.{6})/, "$1T$2Z");
    let location = (
      (event.location.unit ? `${event.location.unit},` : "") +
      `${event.location.address}, ${event.location.city}`
    ).replace(/,/g, "\\,");
    let entry = `BEGIN:VCALENDAR
VERSION:2.0
METHOD:PUBLISH
PRODID:-//Optix//EN
CALSCALE:GREGORIAN
BEGIN:VEVENT
ORGANIZER;CN=${event.user.fullname}:mailto:${event.user.email}
SEQUENCE:1
DTEND:${end}
UID:Optix_${event.booking_id}
DTSTAMP:${start}
LOCATION:${location}
DESCRIPTION:Meeting at ${event.location.name}
URL;VALUE=URI:https://www.optixapp.com/
SUMMARY:Meeting at ${event.location.name}
DTSTART:${start}
ATTENDEE;CN=${event.user.name}:mailto:${event.user.email}
END:VEVENT
END:VCALENDAR`;
    var file = new File(
      [entry],
      "event-" + (event.booking_id || event.tour_id) + ".ics",
      { type: "text/calendar;charset=utf-8" }
    );
    FileSaver.saveAs(file);
  },
};
