export default {
  computed: {
    bookATourTitle() {
      let title = "Book a tour";
      if (
        this.organization &&
        this.organization.tours &&
        this.organization.tours.title
      ) {
        title = this.organization.tours.title;
      }
      return title + " - " + this.bookATourDuration / 60 + " minutes";
    },
    bookATourDuration() {
      // Minimum 5 minutes
      if (
        this.organization &&
        this.organization.tours &&
        this.organization.tours.duration_sec > 5 * 60
      ) {
        return this.organization.tours.duration_sec;
      }
      // Default 45
      return 45 * 60;
    },
    // Default interval for each timeblock of tour
    bookATourStep() {
      // Default 45
      return 30 * 60;
    },
  },
};
