export default {
  // General mutations /////////////////////////////////////////////////////////////////////////////////////////////////
  // Set window mode for this app
  setMode(state, payload) {
    state.mode = payload;
  },
  // Set organization data
  setOrganization(state, payload) {
    Object.assign(state.organization, payload);
  },
  setPaymentGateway(state, payload) {
    state.organization.payment_gateway = payload;
  },
  appendSnackbarError(state, payload) {
    state.generalSnackbar.open = true;
    state.generalSnackbar.type = payload.type || "error";
    state.generalSnackbar.messages = [payload.message];
  },
  closeSnackbar(state) {
    state.generalSnackbar.open = false;
    state.generalSnackbar.messages = [];
  },
  // Set available locations
  setAvailableLocations(state, locations) {
    state.availableLocations = locations;
  },
  // Set base subdomain
  setSubdomain(state, subdomain) {
    state.subdomain = subdomain;
  },
  // Drop-in mutations /////////////////////////////////////////////////////////////////////////////////////////////////
  // Set filter properties
  setFilterInitialDate(state, value) {
    state.filterInitialDate = value;
  },
  setFilterLocations(state, value) {
    state.filterLocations = value;
  },
  setFilterInitialTime(state, value) {
    state.filterInitialTime = value;
  },
  setFilterInitialDuration(state, value) {
    state.filterInitialDuration = value;
  },
  setFilterMinimumCapacity(state, value) {
    state.filterMinimumCapacity = value;
  },
  setFilterResourceType(state, value) {
    state.filterResourceType = value;
  },
  // Set base filters (at the app.page)
  setInitialFilters(state, payload) {
    Object.assign(state, payload);
  },
  selectLocation(state, payload) {
    state.locationSelected = payload;
  },
  selectResource(state, payload) {
    state.resourceSelected = payload.resource;
    state.bookingStartTimestamp = payload.start_timestamp;
    state.bookingDuration = payload.duration;
    state.bookingEndTimestamp = payload.end_timestamp;
    state.locationSelected = payload.location;
  },
  setUser(state, payload) {
    Object.assign(state.user, payload);
  },
  setLookupContext(state, payload) {
    Object.assign(state.lookup, payload);
  },
  setDraftedBooking(state, payload) {
    Object.assign(state.draftedBooking, payload);
  },
  setCommittedBooking(state, payload) {
    Object.assign(state.committedBooking, payload);
  },
  // Tour mutations ////////////////////////////////////////////////////////////////////////////////////////////////////
  startTourCreation(state, payload) {
    state.tourContext.state = "creating";
    state.tourContext.location = payload.location;
    state.tourContext.start_timestamp = payload.start_timestamp;
    state.tourContext.end_timestamp = payload.end_timestamp;
  },
  setCommittedTour(state, payload) {
    state.tourContext.state = "committed";
    Object.assign(state.tourContext, payload);
  },
};
