<template>
  <div
    class="skeleton-loader"
    :style="{
      width: computedWidth,
      height: computedHeight,
      'border-radius': computedRadius,
    }"
    ref="el"
  >
    <div class="effect"></div>
  </div>
</template>
<script>
export default {
  props: {
    width: {
      default: "100%",
    },
    height: {
      default: "100%",
    },
    radius: {
      default: 0,
    },
    margin: {
      default: 0,
    },
  },
  data() {
    return {
      elementWidth: 1,
    };
  },
  mounted() {
    this.elementWidth = this.$refs.el.clientWidth;
  },
  computed: {
    computedWidth() {
      if (this.width.toString().match(/^[0-9]+$/)) {
        return this.width + "px";
      }
      return this.width;
    },
    computedHeight() {
      if (this.height.toString().match(/^x[0-9]+$/)) {
        return (
          (this.height.toString().replace(/^x/) / 100) * this.elementWidth +
          "px"
        );
      }
      if (this.height.toString().match(/^[0-9]+$/)) {
        return this.height + "px";
      }
      return this.height;
    },
    computedRadius() {
      if (this.radius.toString().match(/^[0-9]+$/)) {
        return this.radius + "px";
      }
      return this.radius;
    },
  },
};
</script>
<style scoped>
.skeleton-loader {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.skeleton-loader .effect {
  position: absolute;
  width: 100vh;
  height: 100vh;
  animation-name: aniHorizontal;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  background: linear-gradient(
    to right,
    rgba(var(--optix-theme-oppositeBorderRGBColor), 0.07) 20%,
    rgba(var(--optix-theme-oppositeBorderRGBColor), 0.04) 50%,
    rgba(var(--optix-theme-oppositeBorderRGBColor), 0.07) 80%
  );
  background-size: 50%;
}
@keyframes aniHorizontal {
  0% {
    background-position: -100% 0;
  }

  100% {
    background-position: 100% 0;
  }
}
</style>
